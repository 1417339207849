<template>
  <section
    class="business-nav mt-0"
  >
    <div class="business-nav__content">
      <div
        class="business-nav__item text-md"
        :class="activeNav === 1 ? 'business-nav__item_active' : ''"
        @click="changeNav(1)"
      >
        <div v-html="$t('business/businessNav/partners')"></div>
        <div v-if="activeNav === 1" class="business-nav__active-underline"></div>
      </div>
      <div
        class="business-nav__item text-md"
        :class="activeNav === 2 ? 'business-nav__item_active' : ''"
        @click="changeNav(2)"
      >
        <div v-html="$t('business/businessNav/advertisers')"></div>
        <div v-if="activeNav === 2" class="business-nav__active-underline"></div>
      </div>
    </div>
  </section>
</template>


<script lang="js">
export default {
  name: 'BusinessNav',
  data() {
    return {
      activeNav: 1,
    };
  },
  methods: {
    changeNav(id) {
      this.activeNav = id;
      this.$emit('changenav', id);
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./business-nav";
</style>
