<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card",
}
</script>

<style lang="scss" scoped>
  @import "./card.scss";
</style>