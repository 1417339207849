<template>
  <intro-section
    :bgi="bgi"
  >
    <div
      slot="title"
      class="intro__title"
      v-html="$t('organizers/intro/title')"></div>
    <p
      slot="subtitle"
      class="intro__subtitle" 
      v-html="$t('organizers/intro/subtitle')">
    </p>
    <template v-slot:actions>
      <Button
          class="intro__btn"
          variant="ghost"
          view="light"
          size="lg"
          :text="$t('organizers/intro/howItWorks')"
          @click="scrollToId('how')"
          mobile
        />
        <Button
          class="intro__primary-bg intro__btn"
          variant="primary"
          size="lg"
          :text="$t('organizers/intro/requestCalculation')"
          @click="$emit('requestSettlement')"
          mobile
        />
    </template>
  </intro-section>
</template>


<script lang="js">
import IntroSection from "@/components/sections/intro/intro.vue";
import { scrollToId } from "@/assets/js/animation";

export default {
  name: "Intro",
  components: {
    IntroSection
  },
  data() {
    return {
      bgi: "/img/organizers/organizers-intro-bg.jpg"
    }
  },
  methods: {
    scrollToId,
  },
};
</script>


<style lang="scss" scoped>
  @import "./intro";
</style>
