<template>
  <div class="organizers">
    <intro class="mb-negative-xxl" @requestSettlement="showModal"/>
    <div class="organizers__container">
      <services class="mb-xxl"/>
      <cases class="mb-xxl" @requestSettlement="showModal"></cases>
      <features class="mb-xxl" @requestSettlement="showModal"></features>
    </div>
    <how></how>
    <actions @requestSettlement="showModal"></actions>
    <requestModal ref="requestModal"></requestModal>
  </div>
</template>


<script lang="js">
import { mapGetters } from "vuex";
import intro from "./sections/intro/intro.vue";
import services from "./sections/services/services.vue";
import cases from "./sections/cases/cases.vue";
import features from "./sections/features/features.vue";
import how from "./sections/how/how.vue";
import actions from "./sections/actions/actions.vue";
import requestModal from "./components/request-modal.vue";

export default {
  name: "Organizers",
  components: {
    intro,
    services,
    cases,
    features,
    how,
    actions,
    requestModal
  },
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  created() {
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
  methods: {
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  }
};
</script>


<style lang="scss" scoped>
  @import "./organizers";
</style>
