<template>
  <section>
    <div class="cases">
      <div class="cases__nav">
        <Button
          class="cases__prev"
          variant="secondary"
          size="lg"
          iconLeft="arrow-left"
          shape="circle"
        />
        <Button
          class="cases__next"
          variant="secondary"
          size="lg"
          iconLeft="arrow-right"
          shape="circle"
        />
      </div>
      <div class="cases__container swiper-wrapper">
        <div
          class="cases__item swiper-slide"
          v-for="item in cases"
          :key="item.id"
        >
          <div class="case">
            <div class="case__container">
              <div
                class="case__img"
                :style="'background-image: url(/img/' + item.img + ')'"
              >
                <div class="case__logo">
                  <img :src="'/img/organizers/cases/' + item.logoLight" alt="">
                </div>
              </div>
              <div class="case__info">
                <Card class="case__block">
                  <div class="case__metrics">
                    <div
                      class="case__metrics-item"
                      v-for="(metric, i) in item.metrics"
                      :key="i"
                    >
                      <div class="metrics-item__heading" v-html="metric.val"></div>
                      <b class="text-sm" v-html="metric.text"></b>
                    </div>
                  </div>
                  <div class="case__footer">
                    <div class="case__footer-item case__footer-item--main">
                      <div v-html="item.name"></div>
                    </div>
                    <div class="case__footer-item">
                      <button
                        :data-open-case="item.id"
                        class="link text-lg"
                      ><span v-html="$t('organizers/cases/view')"></span> →</button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cases__pagination"></div>
    </div>
    <case-modal ref="caseModal" class="cases__modal" :data="activeCase" @requestSettlement="showModal"></case-modal>
  </section>
</template>


<script lang="js">
import Swiper, { Pagination, Navigation, EffectFade } from "swiper";
import ScrollMagic from "scrollmagic";
import { TweenLite } from "gsap";
import "swiper/components/effect-fade/effect-fade.scss";
import CaseModal from "@/views/organizers/components/case-modal.vue";
import Card from "@/views/organizers/components/card.vue";

Swiper.use([Pagination, Navigation, EffectFade]);

export default {
  name: "Cases",
  components: {
    Card,
    CaseModal,
  },
  data() {
    return {
      slider: null,
      modal: null,
      activeCase: null,
      blocks: null,
      cases: [
        {
          id: "hc-tbilis",
          name: this.$t("organizers/cases/case-1/name").toString(),
          logoLight: "tbilisi-marathon-white.svg",
          logo: "tbilisi-marathon-colorfull.svg",
          img: "organizers/cases/tbilisi-marathon-bg.jpg",
          metrics: [
            { val: "23%", text: this.$t("organizers/cases/metric-1").toString() },
            { val: `6&#x202F;${this.$t("common/hours").toString()}`, text: this.$t("organizers/cases/metric-3", ["9.8k"]).toString() },
          ],
          description: this.$t("organizers/cases/case-1/description").toString(),
          done: this.$t("organizers/cases/case-1/done"),
          result: this.$t("organizers/cases/case-1/result"),
        },
        {
          id: "bukhara",
          name: this.$t("organizers/cases/case-2/name").toString(),
          logoLight: "bukhara-race-white.png",
          logo: "bukhara-race-black.png",
          img: "organizers/cases/bukhara-slide.jpg",
          metrics: [
            { val: "21%", text: this.$t("organizers/cases/metric-1").toString() },
            { val: `4&#x202F;${this.$t("common/hours").toString()}`, text: this.$t("organizers/cases/metric-3", ["15"]).toString() },
          ],
          description: this.$t("organizers/cases/case-2/description").toString(),
          done: this.$t("organizers/cases/case-2/done"),
          result: this.$t("organizers/cases/case-2/result"),
        },
      ],
    };
  },
  methods: {
    openCase(caseId) {
      const modal = this.modal && this.modal.$refs.modal;

      if (!modal || !caseId) {
        return;
      }

      if (this.activeCase && this.activeCase.id === caseId) {
        modal.open();
        return;
      }

      let activeCase = null;
      for (let i = 0; i < this.cases.length; i += 1) {
        const caseItem = this.cases[i];
        if (caseItem.id === caseId) {
          activeCase = caseItem;
          break;
        }
      }
      this.activeCase = activeCase;

      if (activeCase) {
        modal.open();
      }
    },
    createSlider() {
      const self = this;

      this.slider = new Swiper(".cases", {
        navigation: {
          nextEl: ".cases__next",
          prevEl: ".cases__prev",
        },
        pagination: {
          el: ".cases__pagination",
          type: "bullets",
          clickable: true,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        speed: 750,
        simulateTouch: false,
        autoHeight: true,
        loop: true,
        on: {
          afterInit() {
            const buttons = this.$el[0].querySelectorAll("[data-open-case]");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                self.openCase(button.getAttribute("data-open-case"));
              });
            });
            self.blocks = this.$el[0].querySelectorAll(".case__block");
            self.createScene();
          },
        },
      });

      if (this.$root.useAnimations) {
        this.slider.on("beforeTransitionStart", (swiper, speed, internal) => {
          if (!internal) {
            self.onSlideChange(swiper);
          }
        });
      }
    },
    createScene() {
      if (!this.$root.useAnimations) {
        return;
      }
      const controller = new ScrollMagic.Controller();
      new ScrollMagic.Scene({
        triggerElement: this.blocks[0],
        triggerHook: 1,
        reverse: false,
      })
        .on("start", () => {
          this.animateSlide(1);
        })
        .addTo(controller);
    },
    onSlideChange(swiper) {
      const { activeIndex, previousIndex } = swiper;
      const diff = activeIndex - previousIndex;
      this.animateSlide(activeIndex, diff);
    },
    animateSlide(index, diff) {
      const { slides } = this.slider;

      const block = slides[index].querySelector(".case__block");
      const ease = window.Power2.easeOut;
      const from = {
        y: 40,
        ease,
      };
      if (diff) {
        from.y = 0;
        from.x = 40 * diff;
      }
      TweenLite.set(this.blocks, {
        autoAlpha: 0,
      });
      TweenLite.set(block, {
        autoAlpha: 1,
      });
      TweenLite.fromTo(block, 0.9, from, {
        y: 0,
        x: 0,
        ease,
      });
    },
    showModal() {
      this.$emit('requestSettlement');
    }
  },
  mounted() {
    this.modal = this.$refs.caseModal;
    this.createSlider();
  },
};
</script>


<style lang="scss" scoped>
  @import "./cases";
</style>
