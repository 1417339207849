<template>
  <div class="actions">
    <Button
      variant="primary"
      class="actions__btn"
      size="lg"
      :text="$t('organizers/intro/requestCalculation')"
      @click="$emit('requestSettlement')"
      mobile
    />
  </div>  
</template>


<script lang="js">
export default {
  name: "Actions",
};
</script>


<style lang="scss" scoped>
  @import "./actions";
</style>
