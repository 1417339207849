<template>
  <modal
    name="case-modal"
    align="center"
    ref="modal"
    class="case-modal"
  >
    <div v-if="data">
      <div class="modal__heading">
        <div class="modal__top-img">
          <img :src="'img/organizers/cases/' + data.logo" alt="">
        </div>
        <div class="modal__title" v-html="data.name"></div>
        <div class="modal__subtitle" v-html="data.description"></div>
      </div>
      <div class="modal__content">
        <div class="msg msg_warning">
          <div class="msg__title color-warning text-lg"><b v-html="$t('organizers/caseModal/what')"></b></div>
          <ul class="list text-content mt-0">
            <li
              v-for="(item, i) in data.done"
              :key="i"
              v-html="item"
            ></li>
          </ul>
        </div>
        <div class="msg msg_success">
          <div class="msg__title text-lg color-success"><b v-html="$t('organizers/caseModal/result')"></b></div>
          <ul class="list text-content mt-0">
            <li
              v-for="(item, i) in data.result"
              :key="i"
              v-html="item"
            ></li>
          </ul>
        </div>
      </div>
        <Button 
          class="modal__button"
          variant="secondary"
          :text="$t('organizers/caseModal/request')"
          @click="openRequestModal"
          mobile
        />
    </div>
  </modal>
</template>

<script>
import Modal from "@rr-component-library/modal/src/main";

export default {
  name: "CaseModal",
  components: {
    Modal,
  },
  props: {
    data: Object,
  },
  methods: {
    openRequestModal() {
      this.$refs.modal.close();
      setTimeout(() => { this.$emit('requestSettlement'); }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./case-modal.scss";
</style>
