<template>
  <g>
    <path d="M33.0695 126.398C29.7831 126.398 26.6048 125.224 24.1071 123.088C23.274 122.375 22.3862 121.135 21.4686 119.403C20.883 118.287 20.555 117.055 20.5087 115.796C20.4625 114.536 20.6991 113.283 21.2013 112.127C20.7173 112.62 20.1819 113.059 19.6043 113.437L19.3124 113.632L19.2955 113.282C19.2844 113.054 19.2788 112.827 19.2788 112.607C19.2788 111.311 20.2588 109.997 19.8068 108.799C17.902 103.752 11.8604 98.7353 20.6165 90.9236C21.4265 90.201 20.3445 88.7561 20.3445 87.6628C20.3445 76.9479 31.9867 60.8746 39.7804 68.2307C44.3296 72.5245 53.8077 75.5748 58.233 81.5472L58.2916 81.7242L58.1189 81.7941C57.2347 82.1657 56.3035 82.4134 55.3515 82.5301C56.3664 82.7371 57.4021 82.8239 58.4373 82.7886L58.5978 82.783L58.6369 82.9391C59.022 84.4841 59.2166 86.0705 59.2163 87.6628L59.2156 87.8349C59.212 89.0444 59.4634 90.241 59.9533 91.3468C60.4432 92.4526 61.1606 93.4427 62.0589 94.2526C63.4777 95.5463 64.6114 97.1213 65.3878 98.8773C66.1642 100.633 66.5662 102.532 66.5682 104.452C66.5682 106.723 65.0244 109.705 63.7292 111.807C63.401 112.342 62.9558 112.796 62.427 113.135C61.8983 113.473 61.2997 113.688 60.6762 113.762C60.0857 113.837 59.4859 113.78 58.9195 113.597C58.3531 113.414 57.8338 113.109 57.3984 112.703C58.0115 113.936 58.8103 115.067 59.767 116.057L59.9438 116.239L59.7243 116.367C57.6158 117.596 55.2186 118.244 52.7778 118.243L52.6348 118.242C48.9292 118.242 45.4118 119.678 42.9846 122.181C41.6959 123.509 40.155 124.565 38.4524 125.289C36.7498 126.014 34.9197 126.39 33.0695 126.398Z" fill="#E6E6E6"/>
    <path d="M28.2065 148C28.1468 148 28.0892 147.978 28.0441 147.939C27.9991 147.9 27.9696 147.846 27.9612 147.787C27.9507 147.713 26.9186 140.27 27.8456 130.377C28.7016 121.241 31.4505 108.082 39.6854 97.2745C39.7052 97.2485 39.7298 97.2267 39.758 97.2103C39.7861 97.1939 39.8172 97.1832 39.8496 97.1788C39.8819 97.1744 39.9147 97.1764 39.9462 97.1847C39.9777 97.1931 40.0073 97.2075 40.0332 97.2273C40.0592 97.247 40.0809 97.2717 40.0973 97.2999C40.1137 97.3281 40.1244 97.3592 40.1287 97.3915C40.1331 97.4238 40.131 97.4567 40.1226 97.4882C40.1143 97.5197 40.0998 97.5492 40.08 97.5751C31.9162 108.289 29.1894 121.352 28.3394 130.423C27.4179 140.258 28.4419 147.644 28.4523 147.717C28.4573 147.752 28.4547 147.788 28.4446 147.822C28.4345 147.856 28.4172 147.888 28.3939 147.915C28.3706 147.941 28.3418 147.963 28.3095 147.978C28.2771 147.992 28.242 148 28.2065 148H28.2065Z" fill="#3F3D56"/>
    <path d="M35.7256 112.245C35.6732 112.245 35.6221 112.229 35.5797 112.198C35.5373 112.167 35.5058 112.124 35.4896 112.074C35.4733 112.024 35.4733 111.971 35.4895 111.921C35.5057 111.871 35.5372 111.827 35.5796 111.797C37.926 110.208 40.4609 108.918 43.1258 107.955C47.2891 106.431 53.6201 105.049 60.1378 107.124C60.1688 107.133 60.1976 107.149 60.2225 107.17C60.2474 107.191 60.2679 107.217 60.2829 107.246C60.2978 107.275 60.307 107.306 60.3097 107.339C60.3125 107.371 60.3088 107.404 60.2989 107.435C60.289 107.466 60.2731 107.495 60.2521 107.52C60.2311 107.545 60.2054 107.565 60.1765 107.58C60.1476 107.595 60.116 107.604 60.0836 107.607C60.0511 107.61 60.0184 107.606 59.9874 107.596C53.6033 105.564 47.387 106.923 43.2963 108.421C40.6743 109.367 38.1801 110.636 35.8712 112.198C35.8289 112.229 35.7779 112.245 35.7256 112.245Z" fill="#3F3D56"/>
    <path d="M135.486 126.398C132.2 126.398 129.021 125.224 126.524 123.088C125.69 122.375 124.803 121.135 123.885 119.403C123.299 118.287 122.971 117.055 122.925 115.796C122.879 114.537 123.116 113.283 123.618 112.127C123.134 112.62 122.598 113.059 122.021 113.437L121.729 113.632L121.712 113.282C121.701 113.054 121.695 112.827 121.695 112.607C121.695 111.311 122.675 109.997 122.223 108.799C120.318 103.752 114.277 98.7354 123.033 90.9236C123.843 90.201 122.761 88.7562 122.761 87.6628C122.761 76.9479 137.905 58.4108 142.197 68.2307C147.49 80.3412 156.609 79.0459 160.649 81.5473L160.708 81.7242L160.535 81.7941C159.651 82.1657 158.72 82.4134 157.768 82.5301C158.783 82.7371 159.819 82.8239 160.854 82.7887L161.014 82.7831L161.053 82.9391C161.438 84.4841 161.633 86.0705 161.633 87.6628L161.632 87.8349C161.628 89.0444 161.88 90.241 162.37 91.3468C162.86 92.4526 163.577 93.4427 164.475 94.2527C165.894 95.5463 167.028 97.1213 167.804 98.8773C168.581 100.633 168.983 102.532 168.985 104.452C168.985 106.723 167.441 109.705 166.146 111.807C165.817 112.342 165.372 112.796 164.843 113.135C164.315 113.473 163.716 113.688 163.093 113.762C162.502 113.837 161.902 113.78 161.336 113.597C160.77 113.414 160.25 113.109 159.815 112.703C160.428 113.936 161.227 115.067 162.183 116.057L162.36 116.239L162.141 116.367C160.032 117.596 157.635 118.244 155.194 118.243L155.051 118.242C151.346 118.242 147.828 119.678 145.401 122.181C144.112 123.509 142.571 124.565 140.869 125.289C139.166 126.014 137.336 126.39 135.486 126.398Z" fill="#E6E6E6"/>
    <path d="M130.623 148C130.563 148 130.506 147.978 130.461 147.939C130.416 147.9 130.386 147.846 130.378 147.787C130.367 147.713 129.335 140.27 130.262 130.377C131.118 121.241 133.867 108.082 142.102 97.2745C142.122 97.2485 142.146 97.2267 142.174 97.2103C142.203 97.1939 142.234 97.1832 142.266 97.1788C142.298 97.1744 142.331 97.1764 142.363 97.1847C142.394 97.1931 142.424 97.2075 142.45 97.2273C142.476 97.247 142.497 97.2717 142.514 97.2999C142.53 97.3281 142.541 97.3592 142.545 97.3915C142.55 97.4238 142.547 97.4567 142.539 97.4882C142.531 97.5197 142.516 97.5492 142.496 97.5751C134.333 108.289 131.606 121.352 130.756 130.423C129.834 140.258 130.858 147.644 130.869 147.717C130.874 147.752 130.871 147.788 130.861 147.822C130.851 147.856 130.834 147.888 130.81 147.915C130.787 147.941 130.758 147.963 130.726 147.978C130.694 147.992 130.658 148 130.623 148H130.623Z" fill="#3F3D56"/>
    <path d="M137.462 112.756C137.409 112.756 137.358 112.739 137.316 112.708C137.273 112.678 137.242 112.634 137.226 112.584C137.209 112.535 137.209 112.481 137.225 112.431C137.242 112.381 137.273 112.338 137.316 112.307C139.662 110.719 142.197 109.428 144.862 108.466C149.025 106.941 155.356 105.56 161.874 107.634C161.905 107.644 161.934 107.66 161.959 107.681C161.983 107.702 162.004 107.727 162.019 107.756C162.034 107.785 162.043 107.817 162.046 107.849C162.048 107.882 162.045 107.914 162.035 107.945C162.025 107.976 162.009 108.005 161.988 108.03C161.967 108.055 161.941 108.076 161.913 108.09C161.884 108.105 161.852 108.115 161.82 108.117C161.787 108.12 161.754 108.116 161.723 108.107C155.339 106.075 149.123 107.434 145.032 108.931C142.41 109.878 139.916 111.146 137.607 112.708C137.565 112.739 137.514 112.756 137.462 112.756Z" fill="#3F3D56"/>
    <path d="M126.111 84.4847C126.107 84.4325 126.12 84.3804 126.147 84.3359C126.175 84.2913 126.216 84.2567 126.264 84.237C126.313 84.2172 126.367 84.2133 126.417 84.2259C126.468 84.2384 126.514 84.2667 126.548 84.3068C128.301 86.5328 129.77 88.9681 130.922 91.5567C132.743 95.5995 134.577 101.815 132.977 108.465C132.97 108.496 132.956 108.526 132.937 108.553C132.918 108.579 132.893 108.601 132.866 108.618C132.838 108.635 132.807 108.647 132.775 108.652C132.743 108.657 132.71 108.656 132.678 108.648C132.646 108.64 132.616 108.627 132.59 108.607C132.564 108.588 132.541 108.564 132.524 108.536C132.507 108.509 132.496 108.478 132.491 108.445C132.486 108.413 132.487 108.38 132.495 108.349C134.062 101.835 132.259 95.7326 130.47 91.7604C129.338 89.2133 127.893 86.817 126.168 84.6265C126.135 84.5866 126.114 84.5369 126.111 84.4847Z" fill="#3F3D56"/>
    <path d="M24.2045 82.9536C24.2007 82.9013 24.2136 82.8492 24.2412 82.8047C24.2688 82.7602 24.3098 82.7256 24.3584 82.7058C24.4069 82.6861 24.4604 82.6822 24.5113 82.6947C24.5621 82.7073 24.6077 82.7356 24.6415 82.7756C26.3946 85.0016 27.8642 87.437 29.0164 90.0256C30.8365 94.0684 32.6706 100.283 31.0711 106.934C31.0635 106.965 31.0497 106.995 31.0305 107.021C31.0114 107.048 30.9872 107.07 30.9595 107.087C30.9317 107.104 30.9008 107.116 30.8686 107.121C30.8365 107.126 30.8036 107.124 30.7719 107.117C30.7402 107.109 30.7104 107.095 30.684 107.076C30.6577 107.057 30.6354 107.033 30.6184 107.005C30.6014 106.977 30.59 106.947 30.5849 106.914C30.5799 106.882 30.5812 106.849 30.5888 106.818C32.1555 100.304 30.3524 94.2015 28.564 90.2292C27.4314 87.6821 25.9864 85.2858 24.2623 83.0954C24.2285 83.0554 24.2083 83.0058 24.2045 82.9536Z" fill="#3F3D56"/>
    <path d="M92.6593 57.8819C92.3633 57.5785 91.9993 57.35 91.5974 57.2155C91.1955 57.0809 90.7673 57.0442 90.3484 57.1083C89.9294 57.1723 89.5318 57.3354 89.1884 57.5839C88.8451 57.8324 88.566 58.1592 88.3743 58.5372C84.3391 61.3456 81.7808 65.1086 80.8156 69.9014L73.6569 63.4063C73.7409 62.8629 73.6506 62.307 73.3991 61.8181C73.1475 61.3293 72.7476 60.9326 72.2567 60.6851C71.7658 60.4376 71.2091 60.3519 70.6665 60.4403C70.1238 60.5288 69.6232 60.7868 69.2362 61.1773C68.8493 61.5679 68.596 62.071 68.5127 62.6144C68.4293 63.1578 68.5202 63.7137 68.7724 64.2022C69.0245 64.6908 69.4248 65.087 69.916 65.3339C70.4072 65.5809 70.964 65.666 71.5065 65.5769C72.8206 67.8077 78.9163 77.6724 82.6785 75.4139C85.8408 73.5155 91.4568 64.1097 93.1067 60.9903C93.3708 60.4915 93.4697 59.9218 93.3893 59.3632C93.3089 58.8046 93.0533 58.2859 92.6593 57.8819Z" fill="#FFB6B6"/>
    <path d="M92.8881 62.1894C93.2476 61.215 93.5356 60.141 93.2239 59.1503C92.8811 58.0609 91.8278 57.2807 90.7015 57.091C89.5752 56.9012 88.4047 57.2408 87.4363 57.8464C86.468 58.4521 85.6804 59.3074 84.9807 60.2102L85.1681 59.9119C84.2573 61.1053 83.3464 62.2986 82.4355 63.4919C84.7474 65.0982 87.0594 66.7044 89.3713 68.3106C90.8781 66.4815 92.0669 64.4123 92.8881 62.1894Z" fill="#FF3300"/>
    <path d="M82.9172 144.39L79.6059 144.389L78.0305 131.616L82.9179 131.616L82.9172 144.39Z" fill="#FFB6B6"/>
    <path d="M83.0339 147.844L72.8507 147.844V147.715C72.8508 146.664 73.2684 145.656 74.0117 144.912C74.755 144.169 75.7631 143.751 76.8143 143.751H76.8145L78.6746 142.34L82.1452 143.752L83.0341 143.752L83.0339 147.844Z" fill="#2F2E41"/>
    <path d="M131.493 97.362L132.883 100.367L121.952 107.16L119.9 102.724L131.493 97.362Z" fill="#FFB6B6"/>
    <path d="M134.579 95.8057L138.854 105.048L138.738 105.102C137.783 105.544 136.693 105.588 135.706 105.225C134.72 104.863 133.917 104.123 133.476 103.169L133.476 103.169L131.414 102.073L131.238 98.3306L130.865 97.5238L134.579 95.8057Z" fill="#2F2E41"/>
    <path d="M88.1253 86.6552L86.1127 89.1199C86.1585 89.7874 85.9481 90.1688 85.3231 90.0869C85.3231 90.0869 85.4164 91.309 84.6505 90.9105C83.8847 90.5119 84.0725 91.6183 84.0725 91.6183L83.5003 92.3191C83.5003 92.3191 83.4824 93.511 82.918 93.0322C82.3536 92.5534 82.1726 93.9451 82.1726 93.9451L81.4544 94.8246L74.1324 103.791L78.2369 136.747L83.0341 136.446L84.2035 107.554C84.5017 106.758 84.9544 106.34 85.6465 106.511C85.9565 106.579 86.1378 106.392 86.2519 106.073L86.8034 105.674L94.0224 100.454L103.558 116.796L127.267 104.292L125.055 100.039L108.423 105.542L107.986 100.338C107.418 100.007 107.514 99.6119 107.89 99.189L107.781 97.8906C108.345 97.5445 108.066 96.996 107.658 96.4166C106.806 95.7876 106.889 95.0711 107.481 94.3072C107.481 94.3072 108.228 84.5331 104.606 83.1844C100.984 81.8357 88.1253 86.6552 88.1253 86.6552Z" fill="#2F2E41"/>
    <path d="M95.2355 54.5724L90.6898 54.7852L88.308 58.1878C86.6498 61.8358 86.1946 65.9167 87.008 69.8405L88.6483 77.7524L88.2137 83.6194C87.0493 84.5587 86.9525 85.663 87.9678 86.9392C86.7178 91.2519 97.354 89.311 107.192 87.7899C107.226 85.7351 106.694 83.6429 105.321 81.4952L106.644 64.3537L101.748 58.528L98.0005 56.06L95.2355 54.5724Z" fill="#FF3300"/>
    <path d="M99.4896 59.2739C99.126 59.4918 98.8197 59.7932 98.596 60.1532C98.3723 60.5132 98.2377 60.9213 98.2033 61.3438C98.169 61.7662 98.2358 62.1907 98.3984 62.5821C98.5609 62.9736 98.8144 63.3206 99.138 63.5944C99.6865 67.1527 103.093 70.5806 108.45 73.5724L100.478 79.0383C99.9686 78.8311 99.4068 78.7906 98.8731 78.9225C98.3394 79.0544 97.8612 79.352 97.507 79.7725C97.1528 80.193 96.9409 80.7149 96.9017 81.2632C96.8625 81.8116 96.9979 82.3583 97.2886 82.825C97.5793 83.2916 98.0103 83.6542 98.5198 83.8607C99.0293 84.0673 99.5912 84.1072 100.125 83.9746C100.658 83.8421 101.136 83.5441 101.49 83.1232C101.844 82.7022 102.055 82.1801 102.093 81.6317C104.567 80.868 115.573 77.2144 114.244 73.0324C113.127 69.5173 105.272 61.8816 102.617 59.5561C102.193 59.184 101.661 58.9562 101.099 58.9055C100.537 58.8548 99.9736 58.9838 99.4896 59.2739Z" fill="#FFB6B6"/>
    <path d="M90.8196 75.214C90.2897 75.2131 89.7795 75.0126 89.3908 74.6526C89.002 74.2925 88.7631 73.7992 88.7216 73.2709L88.4393 69.6718C88.3956 69.1145 88.5751 68.5626 88.9383 68.1376C89.3015 67.7126 89.8186 67.4493 90.3759 67.4056L97.3672 66.8573C97.9245 66.8136 98.4763 66.9931 98.9013 67.3563C99.3263 67.7195 99.5896 68.2366 99.6333 68.7939L99.9156 72.393C99.9593 72.9503 99.7798 73.5022 99.4166 73.9272C99.0534 74.3522 98.5363 74.6155 97.979 74.6592L90.9877 75.2075C90.9315 75.2119 90.8754 75.2141 90.8196 75.214Z" fill="#F2F2F2"/>
    <path d="M91.8258 53.7096C95.1129 53.7096 97.7776 51.0449 97.7776 47.7578C97.7776 44.4707 95.1129 41.806 91.8258 41.806C88.5387 41.806 85.874 44.4707 85.874 47.7578C85.874 51.0449 88.5387 53.7096 91.8258 53.7096Z" fill="#FFB6B6"/>
    <path d="M98.0098 43.97C97.7277 43.6039 97.4049 43.2676 97.1593 42.8761C96.9229 42.4991 96.7614 42.076 96.4948 41.7197C95.7065 40.6659 94.2407 40.4504 92.9312 40.3192C92.0665 40.2325 91.2019 40.1459 90.3372 40.0592C89.8513 39.9904 89.3588 39.9815 88.8708 40.0327C88.4064 40.1049 87.9651 40.2835 87.5813 40.5547C87.1976 40.8259 86.8818 41.1822 86.6587 41.5958C86.4054 42.0752 86.2361 42.6726 85.7422 42.8962C85.5094 42.9696 85.2723 43.0285 85.0323 43.0727C84.7989 43.1451 84.5643 43.3315 84.573 43.5757C84.5892 44.0279 85.387 44.2867 85.1923 44.6952C85.0204 45.0559 84.3321 44.7999 84.2344 45.3445C84.2212 45.4231 84.2246 45.5035 84.2443 45.5808C84.264 45.658 84.2996 45.7302 84.3488 45.7929C84.5708 46.0772 84.9351 46.1511 85.2766 46.2139L84.6701 47.0812C86.6337 47.888 88.8196 47.9729 90.8397 47.3207C91.1413 47.2207 91.4632 47.1981 91.7758 47.2549C92.0884 47.3117 92.3817 47.446 92.6289 47.6456C92.8761 47.8452 93.0692 48.1037 93.1905 48.3973C93.3119 48.6909 93.3576 49.0103 93.3234 49.3262L93.3206 49.3509C93.3075 49.4461 93.3204 49.5431 93.358 49.6316C93.3956 49.7201 93.4565 49.7967 93.5341 49.8534C93.8419 50.0625 94.1545 49.7691 94.294 49.4788C94.436 49.1834 94.5302 48.8251 94.8736 48.7216C95.0138 48.6863 95.1616 48.6974 95.2949 48.7532C95.4282 48.8091 95.5398 48.9067 95.6129 49.0314C95.8799 49.4505 95.7703 49.9508 95.6166 50.3944C95.261 51.4181 94.6837 52.3506 93.926 53.1254C95.2191 53.0399 94.8408 53.1695 96.1339 53.084L96.4945 53.1401C97.3598 52.6003 97.7886 51.5732 98.0167 50.5791C98.5343 48.3243 99.4217 45.8027 98.0098 43.97Z" fill="#2F2E41"/>
    <path d="M103.056 58.7867C102.197 58.2036 101.223 57.666 100.187 57.7306C99.0468 57.8016 98.0364 58.6366 97.5817 59.6843C97.1269 60.732 97.1753 61.9499 97.5305 63.0354C97.8857 64.1209 98.5268 65.0909 99.2351 65.987L98.9905 65.7334C99.9301 66.9043 100.87 68.0752 101.809 69.2461C103.924 67.3878 106.038 65.5295 108.153 63.6712C106.74 61.769 105.017 60.1179 103.056 58.7867Z" fill="#FF3300"/>
    <path d="M91.6608 147.531L91.6634 147.413C91.7171 145.424 92.2031 143.471 93.0878 141.689C93.9725 139.908 95.2345 138.34 96.7862 137.096C97.6622 136.404 98.6216 135.826 99.642 135.375L99.7393 135.331L99.756 135.436C99.9536 136.692 100.266 137.974 100.412 138.542L101.39 134.729L101.498 134.698C101.996 134.552 102.526 134.558 103.021 134.716C103.515 134.874 103.951 135.176 104.272 135.584C104.607 135.992 104.812 136.491 104.861 137.016C104.909 137.542 104.8 138.07 104.546 138.532C104.318 138.957 104.094 139.4 103.878 139.828C103.133 141.299 102.364 142.821 101.243 144.003C100.385 144.89 99.3406 145.573 98.1851 146.004C97.0296 146.435 95.7923 146.603 94.5637 146.495L91.6608 147.531Z" fill="#F2F2F2"/>
    <path d="M320.5 225.283C320.5 226.61 319.288 227.897 317.018 229.119C305.378 235.388 265.904 240 218.995 240C217.15 240 215.317 239.993 213.497 239.978C159.994 239.564 117.491 233.143 117.491 225.283C117.491 217.155 162.935 210.566 218.995 210.566C234.347 210.566 248.9 211.061 261.944 211.944C265.222 212.167 268.401 212.414 271.48 212.685C272.761 212.795 274.022 212.911 275.266 213.034C281.127 213.6 286.557 214.254 291.471 214.981C293.857 215.334 296.116 215.703 298.25 216.088C303.807 217.094 308.468 218.206 312.069 219.4C317.49 221.205 320.5 223.194 320.5 225.283Z" fill="#F0F0F0"/>
    <path d="M197.897 103.085L190.486 101.02C190.486 101.02 187.582 100.441 189.356 94.7674C191.13 89.0942 194.648 90.682 194.648 90.682L201.223 92.5933L203.819 93.3954L200.167 98.5224L199.861 101.94L197.897 103.085Z" fill="#3F3D56"/>
    <path d="M212.219 88.0962L214.544 97.3899L202.629 108.944L201.605 109.201L198.872 108.433L196.547 99.1391L198.902 96.6608L196.494 96.0294L201.633 91.0386L204.314 91.6014L206.464 89.465L205.013 88.8844L204.876 88.3378L206.635 86.9531L212.219 88.0962Z" fill="#3F3D56"/>
    <path d="M201.056 101.205L202.595 107.355L209.696 100.638L207.918 94.4023L201.056 101.205Z" fill="#FF3300"/>
    <path d="M212.219 88.0962L212.545 89.3994L200.697 100.932L199.672 101.188L197.026 100.472L196.879 100.468L196.547 99.1391L198.902 96.6608L196.494 96.0294L201.633 91.0386L204.314 91.6014L206.464 89.465L205.013 88.8844L204.876 88.3378L206.634 86.9531L212.219 88.0962Z" stroke="#3F3D56" stroke-miterlimit="10"/>
    <path d="M206.908 88.6272C207.436 88.4949 207.792 88.097 207.702 87.7384C207.612 87.3799 207.111 87.1965 206.583 87.3288C206.055 87.4611 205.7 87.859 205.79 88.2175C205.879 88.5761 206.38 88.7595 206.908 88.6272Z" fill="#FF3300"/>
    <path d="M199.381 98.5745C199.909 98.4422 200.265 98.0443 200.175 97.6857C200.085 97.3272 199.584 97.1438 199.056 97.276C198.528 97.4083 198.173 97.8063 198.263 98.1648C198.352 98.5234 198.853 98.7068 199.381 98.5745Z" fill="#FF3300"/>
    <path d="M192.973 114.663L194.589 109.164C194.589 109.164 190.387 99.1371 195.236 98.1667C200.085 97.1964 200.408 107.547 200.408 107.547L198.468 113.692L192.973 114.663Z" fill="#FFB6B6"/>
    <path d="M222.982 103.972C230.074 103.972 235.823 98.2196 235.823 91.1237C235.823 84.0278 230.074 78.2754 222.982 78.2754C215.89 78.2754 210.141 84.0278 210.141 91.1237C210.141 98.2196 215.89 103.972 222.982 103.972Z" fill="#FFB6B6"/>
    <path d="M218.827 100.193C218.827 100.193 218.072 109.64 216.184 110.396C214.295 111.152 236.578 116.442 236.578 116.442L230.913 110.018C230.913 110.018 229.78 100.193 232.424 97.9258L218.827 100.193Z" fill="#FFB6B6"/>
    <path d="M200.731 111.752L195.559 111.105C195.559 111.105 189.417 114.34 189.417 118.545C189.417 122.75 185.861 129.542 185.861 129.542C185.861 129.542 183.275 138.599 189.094 139.246C194.912 139.892 200.085 129.542 200.085 129.542L200.731 111.752Z" fill="#F0F0F0"/>
    <path d="M197.822 163.18C197.822 163.18 186.508 152.506 183.275 153.153C180.042 153.8 173.577 154.77 173.577 159.622C173.577 164.474 184.245 175.795 184.245 175.795C184.245 175.795 192.326 193.584 200.085 194.231C205.188 194.695 210.261 195.451 215.278 196.496C215.278 196.496 211.722 209.434 213.662 212.992C215.601 216.549 223.036 220.754 223.683 220.431C224.329 220.107 233.381 211.698 237.26 199.73C241.139 187.762 243.725 178.706 240.169 178.059C236.613 177.412 197.822 163.18 197.822 163.18Z" fill="#2F2E41"/>
    <path d="M174.547 158.652C174.547 158.652 172.284 158.329 172.607 163.827C172.931 169.326 173.9 189.056 175.517 192.938C177.133 196.819 177.456 201.347 176.81 202.965C176.163 204.582 174.87 207.17 177.133 207.493C179.396 207.816 188.77 206.523 189.74 206.199C190.71 205.876 191.68 200.377 191.033 200.377C190.387 200.377 188.77 200.054 189.417 197.789C190.064 195.525 190.387 195.525 189.417 194.878C188.447 194.232 185.861 193.261 186.508 192.614C187.154 191.967 187.801 176.765 187.801 176.765L174.547 158.652Z" fill="#2F2E41"/>
    <path d="M177.779 206.522C177.779 206.522 172.284 204.905 171.637 208.463C171.637 208.463 170.668 213.962 174.87 215.903C177.582 217.185 180.178 218.7 182.628 220.431C182.628 220.431 189.417 223.018 189.74 219.784C190.063 216.549 188.447 204.905 188.447 204.905L177.779 206.522Z" fill="#2F2E41"/>
    <path d="M214.308 210.404L224.653 201.994C224.653 201.994 222.713 212.345 225.299 214.285L226.48 217.741L223.172 220.448C223.172 220.448 213.015 218.814 214.308 210.404Z" fill="#2F2E41"/>
    <path d="M228.855 196.172C228.855 196.172 222.066 198.113 223.036 204.905C224.006 211.698 225.249 219.077 225.249 219.077C225.249 219.077 225.622 237.251 230.471 238.544C235.32 239.838 236.613 237.251 236.613 237.251C236.613 237.251 242.125 223.05 240.492 218.814C238.516 213.687 235.668 207.93 236.613 206.199C237.543 204.496 235.967 195.202 228.855 196.172Z" fill="#2F2E41"/>
    <path d="M232.734 106.9C232.734 106.9 230.148 107.224 225.946 107.87C221.743 108.517 218.834 105.283 216.571 105.93C214.308 106.577 195.559 111.105 195.559 111.105L198.468 133.423C198.468 133.423 194.912 159.299 195.559 163.827C196.205 168.356 187.801 167.709 192.649 173.207C197.498 178.706 205.903 183.558 223.036 181.941C240.169 180.323 242.432 180.647 242.432 178.059C242.432 175.472 238.553 153.477 239.846 151.536C241.139 149.596 243.725 147.331 242.432 145.067C241.139 142.803 239.846 141.509 241.785 139.569C243.725 137.628 244.695 135.687 244.695 134.717C244.695 133.746 249.22 117.897 249.22 117.897C249.22 117.897 247.281 110.135 245.018 109.811C242.755 109.488 240.816 107.87 239.199 107.87C237.583 107.87 236.613 108.194 235.643 107.87L232.734 106.9Z" fill="#F0F0F0"/>
    <path d="M204.528 88.8142C206.017 88.6182 207.553 87.7248 208.378 87.1776C209.316 88.085 215.094 93.3176 225.023 94.836C225.023 94.836 225.045 94.6392 225.102 94.3063L228.504 94.8873L234.797 94.2744C234.765 94.3814 234.746 94.44 234.746 94.44C234.746 94.44 235.646 94.1973 235.958 92.1622C236.018 91.7723 236.09 91.3845 236.175 90.9988C236.681 88.6885 237.909 80.3619 230.942 74.7973C225.869 70.7461 220.023 72.0245 216.326 73.5706C214.824 74.1927 213.463 75.1118 212.325 76.2729C211.187 77.434 210.294 78.8131 209.701 80.3276C209.399 81.085 209.197 81.8788 209.101 82.6886L208.957 83.0999C207.834 83.2563 205.581 82.3136 204.37 82.7598C202.715 83.3695 201.901 85.869 201.735 86.6166C201.568 87.3642 202.346 89.1012 204.528 88.8142ZM225.961 91.1639C226.858 88.8561 228.549 86.3355 231.647 86.4611C234.861 86.5914 235.424 89.1447 235.311 91.3283L227.316 91.6192L225.961 91.1639Z" fill="#2F2E41"/>
    <path d="M226.711 72.6592C226.761 72.3677 226.177 72.0248 225.407 71.8933C224.637 71.7618 223.973 71.8915 223.923 72.1831C223.873 72.4746 224.457 72.8175 225.227 72.949C225.997 73.0805 226.661 72.9507 226.711 72.6592Z" fill="#2F2E41"/>
  </g>
</template>

<script>
export default {

}
</script>

<style>

</style>