<template>
  <section class="intro">
    <div
      class="intro__bg"
      :style="`background-image: url(${bgi})`"
      ref="bg"
    ></div>
    <div class="intro__overlay overlay"></div>
    <div class="intro__content">
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
      
      <div class="intro__actions">
        <slot name="actions"></slot>
      </div>    
    </div>
  </section>
</template>


<script lang="js">
export default {
  name: 'Intro',
  props: {
    bgi: {
      type: String,
      default: '/img/organizers/organizers-intro-bg.jpg'
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./intro";
</style>