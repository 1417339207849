<template>
  <g clip-path="url(#clip0_146_234514)">
    <path d="M73.3983 151.463H72.7658V134.136C72.7658 132.819 72.5064 131.515 72.0025 130.299C71.4985 129.082 70.7598 127.976 69.8286 127.045C68.8974 126.114 67.7919 125.375 66.5752 124.871C65.3585 124.367 64.0545 124.108 62.7375 124.108H26.0283C23.3686 124.108 20.8179 125.164 18.9372 127.045C17.0566 128.926 16 131.476 16 134.136V229.192C16 230.509 16.2594 231.813 16.7634 233.03C17.2673 234.247 18.006 235.352 18.9372 236.284C19.8684 237.215 20.9739 237.953 22.1906 238.457C23.4073 238.961 24.7113 239.221 26.0283 239.221H62.7375C65.3972 239.221 67.9479 238.164 69.8286 236.284C71.7093 234.403 72.7658 231.852 72.7658 229.192V163.797H73.3983L73.3983 151.463Z" fill="#E5E5E5"/>
    <path d="M63.1421 126.717H58.3503C58.5707 127.257 58.6548 127.843 58.5953 128.424C58.5357 129.004 58.3344 129.561 58.0089 130.045C57.6835 130.53 57.2439 130.926 56.729 131.201C56.2141 131.475 55.6396 131.619 55.0561 131.619H34.0259C33.4424 131.619 32.8679 131.475 32.353 131.201C31.8381 130.926 31.3985 130.53 31.0731 130.045C30.7476 129.561 30.5462 129.004 30.4867 128.424C30.4272 127.843 30.5113 127.257 30.7317 126.717H26.2561C24.2699 126.717 22.365 127.506 20.9606 128.91C19.5561 130.315 18.7671 132.22 18.7671 134.206V229.123C18.7671 230.106 18.9608 231.08 19.3372 231.989C19.7135 232.897 20.2651 233.723 20.9606 234.418C21.656 235.114 22.4816 235.665 23.3902 236.042C24.2988 236.418 25.2726 236.612 26.2561 236.612H63.1421C64.1256 236.612 65.0994 236.418 66.008 236.042C66.9167 235.665 67.7422 235.114 68.4377 234.418C69.1331 233.723 69.6847 232.897 70.0611 231.989C70.4374 231.08 70.6311 230.106 70.6311 229.123V134.206C70.6311 133.222 70.4374 132.249 70.0611 131.34C69.6847 130.431 69.1331 129.606 68.4377 128.91C67.7422 128.215 66.9167 127.663 66.008 127.287C65.0994 126.911 64.1256 126.717 63.1421 126.717Z" fill="white"/>
    <path d="M62.1151 166.641H28.916V189.039H62.1151V166.641Z" fill="white"/>
    <path d="M62.2293 189.153H28.8013V166.527H62.2293V189.153ZM29.0302 188.925H62.0004V166.756H29.0302V188.925Z" fill="#3F3D56"/>
    <mask id="mask0_146_234514" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="31" y="168" width="29" height="17">
      <path d="M59.3102 168.817V184.602C59.3102 184.654 59.3 184.706 59.28 184.754C59.2601 184.802 59.2308 184.846 59.194 184.883C59.1571 184.92 59.1133 184.949 59.0651 184.969C59.0169 184.989 58.9653 184.999 58.9131 184.999H32.1176C32.0985 184.999 32.0795 184.998 32.0607 184.995C31.9661 184.981 31.8797 184.934 31.8172 184.862C31.7546 184.79 31.7202 184.697 31.7202 184.602V168.817C31.7202 168.765 31.7305 168.714 31.7504 168.665C31.7704 168.617 31.7996 168.573 31.8365 168.536C31.8734 168.5 31.9171 168.47 31.9653 168.45C32.0135 168.43 32.0652 168.42 32.1173 168.42H58.9128C58.965 168.42 59.0166 168.43 59.0649 168.45C59.1131 168.47 59.1569 168.499 59.1938 168.536C59.2307 168.573 59.2599 168.617 59.2799 168.665C59.2999 168.713 59.3102 168.765 59.3102 168.817Z" fill="#E6E6E6"/>
    </mask>
    <g mask="url(#mask0_146_234514)">
      <path d="M59.3102 168.817V184.602C59.3102 184.654 59.3 184.706 59.28 184.754C59.2601 184.802 59.2308 184.846 59.194 184.883C59.1571 184.92 59.1133 184.949 59.0651 184.969C59.0169 184.989 58.9653 184.999 58.9131 184.999H32.1176C32.0985 184.999 32.0795 184.998 32.0607 184.995C31.9661 184.981 31.8797 184.934 31.8172 184.862C31.7546 184.79 31.7202 184.697 31.7202 184.602V168.817C31.7202 168.765 31.7305 168.714 31.7504 168.665C31.7704 168.617 31.7996 168.573 31.8365 168.536C31.8734 168.5 31.9171 168.47 31.9653 168.45C32.0135 168.43 32.0652 168.42 32.1173 168.42H58.9128C58.965 168.42 59.0166 168.43 59.0649 168.45C59.1131 168.47 59.1569 168.499 59.1938 168.536C59.2307 168.573 59.2599 168.617 59.2799 168.665C59.2999 168.713 59.3102 168.765 59.3102 168.817Z" fill="#E6E6E6"/>
    <g opacity="0.25">
      <path d="M38.4747 177.807C40.4002 177.807 41.9611 176.246 41.9611 174.32C41.9611 172.395 40.4002 170.834 38.4747 170.834C36.5492 170.834 34.9883 172.395 34.9883 174.32C34.9883 176.246 36.5492 177.807 38.4747 177.807Z" fill="#FF3300"/>
      <path d="M49.4074 185.059H32.3041C32.2827 185.06 32.2613 185.058 32.2402 185.054L40.3293 171.043C40.3787 170.956 40.4503 170.883 40.5369 170.833C40.6235 170.782 40.722 170.756 40.8222 170.756C40.9225 170.756 41.021 170.782 41.1076 170.833C41.1942 170.883 41.2658 170.956 41.3152 171.043L46.744 180.446L47.0041 180.896L49.4074 185.059Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M49.4078 185.062H43.5684L46.4349 180.898L46.6413 180.598L46.7444 180.448L47.0045 180.898L49.4078 185.062Z" fill="black"/>
      <path d="M58.9306 185.056H44.1377L47.0043 180.892L47.2106 180.592L50.946 175.166C51.1909 174.81 51.78 174.788 52.0679 175.099C52.0873 175.12 52.1052 175.142 52.1217 175.166L58.9306 185.056Z" fill="#3F3D56"/>
    </g>
  <path d="M42.2518 181.852C42.2518 181.852 41.7919 178.479 41.0253 179.399C40.2587 180.319 41.0253 181.699 41.0253 181.699L41.6385 182.465L42.2518 182.005V181.852Z" fill="#F4C2AD"/>
  <path d="M44.0917 182.926C44.0917 182.926 42.5586 181.239 42.2519 181.546C41.9453 181.852 41.0254 182.006 41.1787 182.619C41.332 183.232 44.5517 185.225 44.5517 185.225L44.0917 182.926Z" fill="#FF3300"/>
  <path opacity="0.1" d="M44.0917 182.926C44.0917 182.926 42.5586 181.239 42.2519 181.546C41.9453 181.852 41.0254 182.006 41.1787 182.619C41.332 183.232 44.5517 185.225 44.5517 185.225L44.0917 182.926Z" fill="black"/>
  <path d="M52.8308 182.772V183.999C52.8308 183.999 52.9841 186.758 52.0642 186.605C51.1443 186.452 51.6042 184.152 51.6042 184.152L51.7576 182.772L52.8308 182.772Z" fill="#F4C2AD"/>
  <path d="M48.9979 184.765C48.9979 184.765 51.1443 185.071 50.991 188.138C50.991 188.138 53.7507 190.897 52.9841 191.817C52.2175 192.737 50.531 194.424 49.9178 193.964C49.3045 193.504 47.4647 189.977 47.4647 189.977C47.4647 189.977 43.1718 191.511 42.4052 190.744C41.6387 189.977 40.8721 188.138 40.8721 188.138L44.705 186.144L48.9979 184.765Z" fill="#2F2E41"/>
  <path d="M43.0185 176.027C44.0346 176.027 44.8583 175.203 44.8583 174.187C44.8583 173.171 44.0346 172.347 43.0185 172.347C42.0024 172.347 41.1787 173.171 41.1787 174.187C41.1787 175.203 42.0024 176.027 43.0185 176.027Z" fill="#F4C2AD"/>
  <path d="M44.3979 174.34C44.3979 174.34 45.4711 176.027 45.7778 176.18C46.0844 176.333 43.6313 177.866 43.6313 177.866C43.6313 177.866 43.6313 175.873 42.8647 175.413L44.3979 174.34Z" fill="#F4C2AD"/>
  <path d="M44.5512 176.026C44.5512 176.026 47.0043 175.259 47.4642 176.486C47.9242 177.712 48.9974 180.012 48.8441 181.545C48.6908 183.079 48.3842 182.772 48.8441 183.232C49.3041 183.692 49.6107 183.385 49.4574 183.998C49.3041 184.612 49.1507 184.612 49.3041 184.918C49.4574 185.225 47.4643 186.298 45.7778 186.452L44.0913 186.605C44.0913 186.605 44.0913 183.845 43.7847 183.232C43.478 182.619 42.8647 181.392 42.8647 180.932V179.715C42.8647 178.905 43.0761 178.109 43.478 177.406C43.478 177.406 44.3979 176.026 44.5512 176.026Z" fill="#FF3300"/>
  <path d="M46.3913 176.64C46.3913 176.64 52.064 177.1 52.6773 178.633C53.2905 180.166 53.5972 183.232 52.9839 183.232C52.3706 183.232 51.6041 183.539 51.4507 183.232C51.2974 182.926 50.6842 179.859 50.5308 179.859C50.3775 179.859 45.778 179.399 45.778 179.399C45.778 179.399 43.7849 176.946 46.3913 176.64Z" fill="#FF3300"/>
  <path d="M42.9448 173.73C42.9448 173.73 42.4565 172.882 41.8462 173.619C41.236 174.356 40.2432 173.002 40.3956 172.713C40.548 172.424 41.0862 172.726 42.0085 172.424C42.9309 172.123 44.7076 171.508 45.0052 173.512C45.3028 175.516 44.5941 175.306 44.5941 175.306C44.5941 175.306 44.2692 173.53 43.9756 174.04L43.6821 174.55L43.4274 174.613L42.9448 173.73Z" fill="#2F2E41"/>
  </g>
  <path opacity="0.2" d="M48.5081 183.942H43.877L46.1503 180.64L46.3141 180.402L46.396 180.283L46.602 180.64L48.5081 183.942Z" fill="black"/>
  <path d="M56.0602 183.942H44.3281L46.6016 180.64L46.7652 180.402L49.7277 176.099C49.7801 176.033 49.8457 175.979 49.9203 175.94C49.9949 175.901 50.0768 175.879 50.1607 175.874C50.2447 175.869 50.3287 175.881 50.4074 175.911C50.4861 175.941 50.5577 175.986 50.6175 176.045C50.6328 176.062 50.6471 176.08 50.6602 176.099L56.0602 183.942Z" fill="#3F3D56"/>
  <path d="M142.644 239.307H53.6619C51.5529 239.304 49.5309 238.465 48.0396 236.974C46.5483 235.483 45.7094 233.461 45.707 231.352V102.308C45.7094 100.199 46.5483 98.1773 48.0396 96.686C49.5309 95.1947 51.5529 94.3559 53.6619 94.3535H142.644C144.753 94.3559 146.775 95.1947 148.266 96.686C149.757 98.1773 150.596 100.199 150.598 102.308V231.352C150.596 233.461 149.757 235.483 148.266 236.974C146.775 238.465 144.753 239.304 142.644 239.307Z" fill="#E5E5E5"/>
  <path d="M142.8 234.231H53.5049C52.8548 234.231 52.2315 233.972 51.7718 233.512C51.3121 233.053 51.0535 232.429 51.0527 231.779V101.881C51.0535 101.231 51.3121 100.608 51.7718 100.148C52.2315 99.6882 52.8548 99.4296 53.5049 99.4288H142.8C143.45 99.4296 144.073 99.6882 144.533 100.148C144.993 100.608 145.251 101.231 145.252 101.881V231.779C145.251 232.429 144.993 233.053 144.533 233.512C144.073 233.972 143.45 234.231 142.8 234.231Z" fill="white"/>
  <path d="M97.8139 97.4663C98.1877 97.4663 98.4906 97.1634 98.4906 96.7896C98.4906 96.4159 98.1877 96.1129 97.8139 96.1129C97.4402 96.1129 97.1372 96.4159 97.1372 96.7896C97.1372 97.1634 97.4402 97.4663 97.8139 97.4663Z" fill="white"/>
  <path d="M135.367 136.512H65.1089V183.911H135.367V136.512Z" fill="white"/>
  <path d="M135.609 184.153H64.8667V136.269H135.609V184.153ZM65.3512 183.669H135.125V136.754H65.3512V183.669Z" fill="#3F3D56"/>
  <mask id="mask1_146_234514" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="71" y="140" width="59" height="36">
  <path d="M129.43 141.111V174.511C129.43 174.621 129.408 174.73 129.366 174.832C129.324 174.934 129.262 175.027 129.184 175.105C129.106 175.183 129.013 175.245 128.911 175.287C128.809 175.33 128.7 175.352 128.589 175.352H71.8907C71.8503 175.352 71.81 175.349 71.7702 175.342C71.5702 175.313 71.3873 175.213 71.2549 175.061C71.1226 174.908 71.0498 174.713 71.0498 174.511V141.111C71.0498 141 71.0715 140.891 71.1137 140.789C71.1559 140.687 71.2178 140.594 71.2958 140.516C71.3738 140.438 71.4665 140.376 71.5685 140.334C71.6704 140.292 71.7797 140.27 71.8901 140.27H128.589C128.699 140.27 128.809 140.292 128.911 140.334C129.013 140.376 129.105 140.438 129.183 140.516C129.261 140.594 129.323 140.687 129.366 140.789C129.408 140.891 129.43 141 129.43 141.111Z" fill="#E6E6E6"/>
  </mask>
  <g mask="url(#mask1_146_234514)">
  <path d="M129.43 141.111V174.511C129.43 174.621 129.408 174.73 129.366 174.832C129.324 174.934 129.262 175.027 129.184 175.105C129.106 175.183 129.013 175.245 128.911 175.287C128.809 175.33 128.7 175.352 128.589 175.352H71.8907C71.8503 175.352 71.81 175.349 71.7702 175.342C71.5702 175.313 71.3873 175.213 71.2549 175.061C71.1226 174.908 71.0498 174.713 71.0498 174.511V141.111C71.0498 141 71.0715 140.891 71.1137 140.789C71.1559 140.687 71.2178 140.594 71.2958 140.516C71.3738 140.438 71.4665 140.376 71.5685 140.334C71.6704 140.292 71.7797 140.27 71.8901 140.27H128.589C128.699 140.27 128.809 140.292 128.911 140.334C129.013 140.376 129.105 140.438 129.183 140.516C129.261 140.594 129.323 140.687 129.366 140.789C129.408 140.891 129.43 141 129.43 141.111Z" fill="#E6E6E6"/>
  <g opacity="0.25">
  <path d="M85.342 160.132C89.4163 160.132 92.7192 156.829 92.7192 152.755C92.7192 148.68 89.4163 145.377 85.342 145.377C81.2677 145.377 77.9648 148.68 77.9648 152.755C77.9648 156.829 81.2677 160.132 85.342 160.132Z" fill="#FF3300"/>
  <path d="M108.476 175.479H72.2856C72.2403 175.48 72.195 175.476 72.1504 175.468L89.2667 145.82C89.3712 145.636 89.5228 145.482 89.706 145.375C89.8893 145.268 90.0977 145.212 90.3098 145.212C90.5219 145.212 90.7303 145.268 90.9135 145.375C91.0968 145.482 91.2484 145.636 91.3529 145.82L102.84 165.716L103.39 166.668L108.476 175.479Z" fill="#3F3D56"/>
  <path opacity="0.2" d="M108.476 175.484H96.1201L102.186 166.673L102.622 166.039L102.841 165.721L103.391 166.673L108.476 175.484Z" fill="black"/>
  <path d="M128.626 175.471H97.3247L103.39 166.66L103.827 166.026L111.731 154.544C112.249 153.791 113.496 153.745 114.105 154.402C114.146 154.447 114.184 154.494 114.219 154.544L128.626 175.471Z" fill="#3F3D56"/>
  </g>
  <path d="M93.3348 168.692C93.3348 168.692 92.3616 161.555 90.7395 163.501C89.1174 165.448 90.7395 168.368 90.7395 168.368L92.0372 169.99L93.3348 169.016V168.692Z" fill="#F4C2AD"/>
  <path d="M97.2279 170.964C97.2279 170.964 93.9838 167.395 93.3349 168.044C92.6861 168.693 90.7396 169.017 91.064 170.315C91.3884 171.613 98.2012 175.83 98.2012 175.83L97.2279 170.964Z" fill="#FF3300"/>
  <path opacity="0.1" d="M97.2279 170.964C97.2279 170.964 93.9838 167.395 93.3349 168.044C92.6861 168.693 90.7396 169.017 91.064 170.315C91.3884 171.613 98.2012 175.83 98.2012 175.83L97.2279 170.964Z" fill="black"/>
  <path d="M115.719 170.639V173.234C115.719 173.234 116.044 179.074 114.097 178.749C112.151 178.425 113.124 173.559 113.124 173.559L113.449 170.639L115.719 170.639Z" fill="#F4C2AD"/>
  <path d="M107.609 174.855C107.609 174.855 112.151 175.504 111.827 181.992C111.827 181.992 117.666 187.831 116.044 189.778C114.422 191.724 110.853 195.293 109.556 194.32C108.258 193.347 104.365 185.885 104.365 185.885C104.365 185.885 95.2813 189.129 93.6592 187.507C92.0371 185.885 90.415 181.992 90.415 181.992L98.5255 177.775L107.609 174.855Z" fill="#2F2E41"/>
  <path d="M94.9565 156.366C97.1065 156.366 98.8495 154.623 98.8495 152.473C98.8495 150.323 97.1065 148.58 94.9565 148.58C92.8064 148.58 91.0635 150.323 91.0635 152.473C91.0635 154.623 92.8064 156.366 94.9565 156.366Z" fill="#F4C2AD"/>
  <path d="M97.876 152.797C97.876 152.797 100.147 156.365 100.796 156.69C101.445 157.014 96.2539 160.258 96.2539 160.258C96.2539 160.258 96.2539 156.041 94.6318 155.068L97.876 152.797Z" fill="#F4C2AD"/>
  <path d="M98.2004 156.364C98.2004 156.364 103.391 154.742 104.364 157.337C105.338 159.933 107.608 164.799 107.284 168.043C106.96 171.287 106.311 170.639 107.284 171.612C108.257 172.585 108.906 171.936 108.582 173.234C108.257 174.531 107.933 174.531 108.257 175.18C108.582 175.829 104.364 178.1 100.796 178.424L97.2272 178.749C97.2272 178.749 97.2272 172.909 96.5783 171.612C95.9295 170.314 94.6318 167.719 94.6318 166.746V164.17C94.6318 162.456 95.0791 160.772 95.9295 159.284C95.9295 159.284 97.876 156.364 98.2004 156.364Z" fill="#FF3300"/>
  <path d="M102.094 157.663C102.094 157.663 114.097 158.636 115.395 161.88C116.693 165.124 117.342 171.613 116.044 171.613C114.746 171.613 113.124 172.261 112.8 171.613C112.475 170.964 111.178 164.475 110.853 164.475C110.529 164.475 100.796 163.502 100.796 163.502C100.796 163.502 96.5789 158.311 102.094 157.663Z" fill="#FF3300"/>
  <path d="M94.8003 151.507C94.8003 151.507 93.7672 149.712 92.4759 151.271C91.1845 152.83 89.0838 149.965 89.4063 149.353C89.7288 148.742 90.8676 149.381 92.8193 148.743C94.771 148.105 98.5305 146.805 99.1602 151.045C99.7898 155.285 98.2902 154.84 98.2902 154.84C98.2902 154.84 97.6029 151.083 96.9816 152.162L96.3604 153.241L95.8215 153.374L94.8003 151.507Z" fill="#2F2E41"/>
  </g>
  <path opacity="0.2" d="M106.571 173.125H96.77L101.581 166.137L101.927 165.633L102.1 165.382L102.537 166.137L106.571 173.125Z" fill="black"/>
  <path d="M122.554 173.125H97.7261L102.537 166.137L102.884 165.633L109.153 156.526C109.264 156.387 109.403 156.272 109.561 156.19C109.719 156.108 109.892 156.061 110.069 156.05C110.247 156.039 110.425 156.066 110.591 156.129C110.758 156.191 110.909 156.288 111.036 156.413C111.069 156.449 111.099 156.486 111.126 156.526L122.554 173.125Z" fill="#3F3D56"/>
  <path d="M230.986 237.774L226.482 235.878L223.392 213.299H182.246L178.897 235.786L174.867 237.8C174.674 237.897 174.52 238.055 174.429 238.251C174.337 238.446 174.314 238.666 174.364 238.876C174.414 239.086 174.532 239.273 174.701 239.407C174.87 239.54 175.08 239.613 175.295 239.613H230.615C230.836 239.613 231.05 239.537 231.221 239.397C231.392 239.257 231.509 239.062 231.553 238.846C231.596 238.629 231.564 238.404 231.461 238.209C231.357 238.013 231.19 237.86 230.986 237.774Z" fill="#E6E6E6"/>
  <path d="M304.105 216.303H101.985C100.924 216.3 99.908 215.877 99.1589 215.127C98.4099 214.376 97.9893 213.359 97.9893 212.298V183.797H308.101V212.298C308.101 213.359 307.68 214.376 306.931 215.127C306.182 215.877 305.166 216.3 304.105 216.303Z" fill="#CCCCCC"/>
  <path d="M308.312 197.267H97.8047V75.3146C97.8062 74.0348 98.3152 72.8078 99.2202 71.9028C100.125 70.9979 101.352 70.4889 102.632 70.4874H303.485C304.764 70.4889 305.992 70.9979 306.897 71.9028C307.802 72.8078 308.311 74.0348 308.312 75.3146V197.267Z" fill="#E5E5E5"/>
  <path d="M295.739 188.416H110.377C109.391 188.415 108.445 188.022 107.747 187.325C107.049 186.627 106.657 185.681 106.656 184.695V83.0602C106.657 82.0735 107.049 81.1276 107.747 80.4299C108.445 79.7322 109.391 79.3397 110.377 79.3386H295.739C296.726 79.3397 297.672 79.7322 298.369 80.4299C299.067 81.1276 299.46 82.0735 299.461 83.0602V184.695C299.46 185.681 299.067 186.627 298.369 187.325C297.672 188.022 296.726 188.415 295.739 188.416Z" fill="white"/>
  <path d="M321.551 240.186H0.464981C0.363656 240.188 0.264462 240.156 0.18286 240.096C0.101259 240.036 0.0418328 239.951 0.0138358 239.853C-0.00364412 239.787 -0.00568418 239.718 0.00788058 239.65C0.0214453 239.583 0.0502525 239.52 0.0920788 239.466C0.133905 239.411 0.187639 239.367 0.249145 239.337C0.310651 239.307 0.378288 239.291 0.446855 239.291H321.515C321.622 239.287 321.727 239.32 321.813 239.383C321.899 239.447 321.961 239.537 321.988 239.641C322.003 239.706 322.003 239.774 321.987 239.839C321.972 239.905 321.942 239.966 321.901 240.018C321.859 240.07 321.805 240.112 321.745 240.141C321.685 240.17 321.618 240.186 321.551 240.186Z" fill="#CCCCCC"/>
  <path d="M247.092 115.331C248.043 112.299 250.207 109.518 253.2 108.492C256.192 107.467 261.413 108.426 262.824 111.271C264.858 115.372 263.944 120.394 262.866 124.847C262.303 124.817 261.736 124.794 261.166 124.773L260.361 122.089V124.746C255.919 124.609 251.293 124.695 246.666 124.784C246.251 121.631 246.14 118.364 247.092 115.331Z" fill="#2F2E41"/>
  <path d="M287.915 230.882L284.177 232.448L276.358 218.778L281.874 216.466L287.915 230.882Z" fill="#FFAE95"/>
  <path d="M290.386 234.105L278.336 239.155L278.272 239.002C277.751 237.758 277.745 236.358 278.256 235.11C278.767 233.862 279.753 232.868 280.997 232.346L280.997 232.346L288.357 229.262L290.386 234.105Z" fill="#2F2E41"/>
  <path d="M233.109 166.309C232.876 165.972 232.719 165.588 232.65 165.184C232.581 164.78 232.601 164.366 232.71 163.971C232.818 163.576 233.012 163.209 233.277 162.897C233.543 162.584 233.874 162.334 234.246 162.164L242.279 137.925L247.658 140.794L238.071 163.718C238.316 164.363 238.316 165.075 238.071 165.72C237.826 166.365 237.353 166.898 236.741 167.217C236.129 167.537 235.422 167.621 234.752 167.453C234.083 167.286 233.498 166.879 233.109 166.309L233.109 166.309Z" fill="#FFAE95"/>
  <path d="M243.013 235.227H238.96L237.033 219.597H243.013L243.013 235.227Z" fill="#FFAE95"/>
  <path d="M244.046 239.155L230.98 239.154V238.989C230.981 237.64 231.516 236.347 232.47 235.393C233.424 234.439 234.717 233.904 236.066 233.904H236.066L244.047 233.904L244.046 239.155Z" fill="#2F2E41"/>
  <path d="M259.259 146.328L260.178 148.716L259.994 151.103L242.365 148.899L242.916 146.879L244.936 144.492L259.259 146.328Z" fill="#FFAE95"/>
  <path d="M253.578 124.846C257.123 124.846 259.996 121.973 259.996 118.428C259.996 114.883 257.123 112.01 253.578 112.01C250.034 112.01 247.16 114.883 247.16 118.428C247.16 121.973 250.034 124.846 253.578 124.846Z" fill="#FFAE95"/>
  <path d="M246.773 129.434L250.445 127.231L257.79 126.496L264.283 127.965L264.218 139.901L260.178 148.716L242.916 146.879L242.182 144.478C242.182 144.478 237.407 138.065 242.549 134.392L246.773 129.434Z" fill="#FF3300"/>
  <path d="M247.691 129.985L246.773 129.434C246.773 129.434 241.815 130.352 240.713 133.291C239.611 136.229 238.509 149.45 238.509 149.45L243.1 149.995L244.753 139.167L247.691 129.985Z" fill="#FF3300"/>
  <path d="M260.177 150.185V154.264C260.177 154.264 264.952 160.468 262.381 165.242C262.381 165.242 267.89 175.526 268.257 179.198L273.399 196.093C273.399 196.093 286.253 208.947 285.151 218.128L285.886 221.066L277.439 224.739L275.602 222.168L261.279 197.562L249.16 175.159L246.956 201.602C246.956 201.602 248.425 222.168 244.385 224.372L244.018 227.677H235.445V224.535C235.445 224.535 232.265 213.354 234.102 204.907L233.367 169.282C233.367 169.282 236.673 154.225 240.712 151.654L242.182 147.981L260.177 150.185Z" fill="#2F2E41"/>
  <path d="M249.168 110.071C250.401 109.319 251.814 108.912 253.258 108.893C254.702 108.874 256.125 109.243 257.377 109.962C258.63 110.681 259.666 111.724 260.378 112.98C261.09 114.237 261.451 115.662 261.424 117.106C257.836 117.271 254.068 117.314 250.246 117.321L249.343 115.111L248.811 117.323C247.647 117.323 246.48 117.321 245.311 117.318C245.263 115.878 245.595 114.451 246.271 113.179C246.948 111.907 247.947 110.835 249.168 110.071Z" fill="#2F2E41"/>
  <path d="M238.755 110.853L152.352 132.826L167.176 191.117L253.579 169.145L238.755 110.853Z" fill="white"/>
  <path d="M253.952 169.367L166.954 191.491L151.979 132.604L238.977 110.479L253.952 169.367ZM167.398 190.743L253.205 168.922L238.533 111.227L152.726 133.048L167.398 190.743Z" fill="#3F3D56"/>
  <path opacity="0.2" d="M214.793 164.885L202.74 167.951L206.471 157.852L206.739 157.124L206.874 156.761L207.646 157.553L214.793 164.885Z" fill="black"/>
  <mask id="mask2_146_234514" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="161" y="117" width="83" height="62">
  <path d="M232.448 118.23L242.988 158.954C243.023 159.089 243.031 159.229 243.012 159.366C242.992 159.504 242.946 159.637 242.876 159.756C242.805 159.876 242.712 159.981 242.601 160.065C242.49 160.149 242.363 160.21 242.229 160.244L173.096 178.137C173.047 178.15 172.997 178.159 172.946 178.164C172.693 178.192 172.439 178.128 172.229 177.983C172.02 177.839 171.869 177.624 171.806 177.377L161.265 136.653C161.23 136.519 161.222 136.378 161.242 136.241C161.261 136.103 161.307 135.97 161.378 135.851C161.448 135.731 161.542 135.626 161.653 135.542C161.764 135.459 161.89 135.398 162.025 135.363L231.157 117.47C231.292 117.435 231.432 117.427 231.57 117.446C231.707 117.465 231.84 117.512 231.96 117.582C232.08 117.653 232.184 117.746 232.268 117.857C232.352 117.968 232.413 118.095 232.448 118.23Z" fill="#E6E6E6"/>
  </mask>
  <g mask="url(#mask2_146_234514)">
  <path d="M232.448 118.23L242.988 158.954C243.023 159.089 243.031 159.229 243.012 159.366C242.992 159.504 242.946 159.637 242.876 159.756C242.805 159.876 242.712 159.981 242.601 160.065C242.49 160.149 242.363 160.21 242.229 160.244L173.096 178.137C173.047 178.15 172.997 178.159 172.946 178.164C172.693 178.192 172.439 178.128 172.229 177.983C172.02 177.839 171.869 177.624 171.806 177.377L161.265 136.653C161.23 136.519 161.222 136.378 161.242 136.241C161.261 136.103 161.307 135.97 161.378 135.851C161.448 135.731 161.542 135.626 161.653 135.542C161.764 135.459 161.89 135.398 162.025 135.363L231.157 117.47C231.292 117.435 231.432 117.427 231.57 117.446C231.707 117.465 231.84 117.512 231.96 117.582C232.08 117.653 232.184 117.746 232.268 117.857C232.352 117.968 232.413 118.095 232.448 118.23Z" fill="#E6E6E6"/>
  <g opacity="0.25">
  <path d="M184.694 155.335C189.662 154.049 192.647 148.98 191.361 144.012C190.075 139.044 185.006 136.059 180.038 137.345C175.07 138.631 172.085 143.7 173.371 148.668C174.657 153.636 179.726 156.621 184.694 155.335Z" fill="#FF3300"/>
  <path d="M217.744 166.747L173.618 178.168C173.563 178.183 173.506 178.193 173.45 178.198L184.963 136.646C185.032 136.388 185.169 136.153 185.358 135.965C185.548 135.777 185.784 135.642 186.043 135.576C186.302 135.509 186.574 135.512 186.831 135.584C187.088 135.656 187.321 135.796 187.507 135.988L207.792 156.622L208.763 157.609L217.744 166.747Z" fill="#3F3D56"/>
  <path opacity="0.2" d="M217.746 166.753L202.68 170.652L207.296 157.995L207.628 157.084L207.794 156.628L208.765 157.615L217.746 166.753Z" fill="black"/>
  <path d="M242.311 160.379L204.145 170.257L208.761 157.6L209.093 156.688L215.107 140.194C215.501 139.113 217.006 138.662 217.956 139.272C218.02 139.314 218.082 139.359 218.14 139.409L242.311 160.379Z" fill="#3F3D56"/>
  </g>
  <path d="M197.142 163.25C197.142 163.25 193.703 154.855 192.34 157.74C190.976 160.625 193.875 163.673 193.875 163.673L195.969 165.242L197.245 163.646L197.142 163.25Z" fill="#F4C2AD"/>
  <path d="M202.606 164.791C202.606 164.791 197.524 161.464 196.938 162.46C196.351 163.456 194.08 164.466 194.885 165.945C195.69 167.425 205.328 170.418 205.328 170.418L202.606 164.791Z" fill="#FF3300"/>
  <path opacity="0.1" d="M202.606 164.791C202.606 164.791 197.524 161.464 196.938 162.46C196.351 163.456 194.08 164.466 194.885 165.945C195.69 167.425 205.328 170.418 205.328 170.418L202.606 164.791Z" fill="black"/>
  <path d="M225.05 158.56L225.869 161.724C225.869 161.724 228.107 168.742 225.632 168.961C223.156 169.18 222.807 162.939 222.807 162.939L222.281 159.277L225.05 158.56Z" fill="#F4C2AD"/>
  <path d="M229.801 181.811C229.801 181.811 234.795 186.427 236.084 186.516C237.373 186.604 244.623 188.526 246.336 191.881L253.716 197.568L251.869 201.845C251.869 201.845 235.73 191.672 231.863 191.407C227.996 191.141 222.9 186.129 222.9 186.129L229.801 181.811Z" fill="#F4C2AD"/>
  <path d="M199.362 179.982C199.362 179.982 192.153 183.114 194.568 187.553C196.984 191.993 211.014 198.913 211.014 198.913L212.024 201.184L216.58 200.849L218.046 198.359L217.343 197.275C217.343 197.275 211.47 194.152 210.665 192.673C209.86 191.193 203.871 185.99 201.791 186.106L204.15 183.807L199.362 179.982Z" fill="#F4C2AD"/>
  <path d="M216.492 166.26C216.492 166.26 222.234 165.617 223.886 173.631C223.886 173.631 232.849 178.908 231.485 181.794C230.122 184.679 226.897 190.156 225.008 189.379C223.118 188.602 216.017 180.732 216.017 180.732C216.017 180.732 205.965 187.555 203.475 186.089C200.986 184.623 197.779 180.388 197.779 180.388L206.337 172.686L216.492 166.26Z" fill="#2F2E41"/>
  <path d="M195.23 147.709C197.851 147.03 199.426 144.355 198.748 141.733C198.069 139.112 195.394 137.537 192.773 138.215C190.151 138.894 188.576 141.569 189.254 144.19C189.933 146.812 192.608 148.387 195.23 147.709Z" fill="#F4C2AD"/>
  <path d="M197.664 142.436C197.664 142.436 201.559 146.07 202.452 146.261C203.346 146.452 198.041 152.046 198.041 152.046C198.041 152.046 196.71 146.903 194.425 146.229L197.664 142.436Z" fill="#F4C2AD"/>
  <path d="M199.184 146.683C199.184 146.683 205.001 143.067 207.007 145.925C209.013 148.782 213.317 153.999 213.946 158.057C214.574 162.115 213.578 161.528 215.072 162.408C216.566 163.288 217.152 162.292 217.166 163.976C217.18 165.661 216.784 165.763 217.385 166.452C217.985 167.141 213.559 171.241 209.311 172.762L205.062 174.284C205.062 174.284 203.219 167.164 202.018 165.786C200.818 164.409 198.417 161.654 198.109 160.467L197.297 157.327C196.756 155.238 196.77 153.043 197.337 150.96C197.337 150.96 198.789 146.786 199.184 146.683Z" fill="#FF3300"/>
  <path d="M204.341 147.038C204.341 147.038 219.284 144.436 221.89 147.983C224.496 151.529 227.335 159.235 225.752 159.645C224.17 160.054 222.397 161.357 221.797 160.668C221.197 159.98 217.567 152.478 217.171 152.58C216.776 152.683 204.602 154.567 204.602 154.567C204.602 154.567 197.821 149.569 204.341 147.038Z" fill="#FF3300"/>
  <path d="M193.506 141.834C193.506 141.834 191.679 139.971 190.597 142.28C189.514 144.588 186.049 141.757 186.249 140.91C186.449 140.063 188.039 140.482 190.218 139.088C192.396 137.695 196.57 134.923 198.676 139.894C200.781 144.865 198.813 144.797 198.813 144.797C198.813 144.797 196.789 140.433 196.372 141.944L195.955 143.456L195.34 143.788L193.506 141.834Z" fill="#2F2E41"/>
  </g>
  <path d="M254.073 158.618C254.221 158.651 254.366 158.696 254.507 158.752L265.711 151.04L265.455 147.746L270.376 146.709L271.397 152.497C271.479 152.965 271.408 153.446 271.193 153.87C270.979 154.293 270.632 154.636 270.207 154.846L256.328 161.691C256.275 162.273 256.047 162.825 255.672 163.274C255.298 163.724 254.796 164.048 254.233 164.205C253.669 164.362 253.072 164.343 252.519 164.152C251.967 163.961 251.486 163.606 251.14 163.135C250.794 162.664 250.599 162.098 250.583 161.514C250.566 160.929 250.728 160.354 251.046 159.863C251.364 159.373 251.825 158.991 252.365 158.769C252.906 158.546 253.502 158.494 254.073 158.618Z" fill="#FFAE95"/>
<path d="M261.646 128.516L264.282 127.965C264.282 127.965 270.094 125.945 270.461 133.291L271.195 150.552L265.74 151.286L263.483 136.229L261.646 128.516Z" fill="#FF3300"/>
</g>
</template>

<script>
export default {

}
</script>

<style>

</style>