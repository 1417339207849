<template>
  <section
    id="how"
    class="section section_lg org-how"
    :style="'background-image: url(\'/img/business/how.jpg\')'"
  >
    <div class="org-how__overlay-1"></div>
    <div class="container org-how__container">
      <div class="section__group m-0">
        <div class="org-how__title" v-html="$t('business/how/header')"></div>
        <div class="org-how__text" v-html="$t('business/how/subheader')"></div>
      </div>
    </div>
  </section>
</template>


<script lang="js">
export default {
  name: 'BusinessHow',
};
</script>


<style lang="scss" scoped>
  @import "./business-how";
</style>
