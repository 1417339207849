<template>
  <section class="services" ref="services">
    <Row justify="center">
      <Col 
        v-for="(item, i) in services" 
        :key="i" 
        :size="{'default': '12', 'md': '6'}"
        class="services__item"
      >
        <Card class="services__block">
          <div class="card__title">{{ item.title }}</div>
          <base-illustration 
            width="261" 
            height="194" 
            viewBox="0 0 322 240" 
            class="services__image">
            <component :is="item.img" />
          </base-illustration>
          <p class="card__text">{{ item.text }}</p>
        </Card>
      </Col>
    </Row>  
  </section>
</template>


<script lang="js">
import ScrollMagic from "scrollmagic";
import { TweenLite, TimelineLite } from "gsap";
import Card from "@/views/organizers/components/card.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import PostingPhoto from "@/components/illustrations/posting-photo.vue";
import PhotoSession from "@/components/illustrations/photo-session.vue";

export default {
  name: "Services",
  components: {
    Card,
    BaseIllustration,
    PostingPhoto,
    PhotoSession
  },
  data() {
    return {
      services: [
        {
          title: this.$t("organizers/services/title-1").toString(),
          text: this.$t("organizers/services/text-1").toString(),
          img: "photo-session",
        },
        {
          title: this.$t("organizers/services/title-2").toString(),
          text: this.$t("organizers/services/text-2").toString(),
          img:"posting-photo"
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { services } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;
      const tl = new TimelineLite();

      TweenLite.set(services, {
        y: 40,
      });

      tl.to(services, 0.9, {
          y: 0,
          ease,
      });

      new ScrollMagic.Scene({
        triggerElement: services,
        triggerHook: 1,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./services";
</style>
