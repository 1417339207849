<template>
  <section class="services" ref="blocks">
    <Row>
      <Col
        :size="{'default': '12', 'md': '4'}"
        v-for="(item, i) in services"
        :key="i"
        class="services__item"
      >
        <Card class="services__card">
          <img
            :src="`/img/business/${item.img}`"
            class="block__intro-image"
            alt=""
            width="100%"
          >
          <div v-html="item.title"></div>
          <p v-html="item.text"></p>
        </Card>
      </Col>
    </Row>
  </section>
</template>


<script lang="js">
import ScrollMagic from 'scrollmagic';
import { TweenLite, TimelineLite } from 'gsap';
import Card from '@/views/organizers/components/card.vue';

export default {
  name: 'AdvertiserServices',
  components: {
    Card
  },
  data() {
    return {
      services: [
        {
          title: this.$t('business/services/title-1').toString(),
          text: this.$t('business/services/text-1').toString(),
          img: 'example-banner.png',
        },
        {
          title: this.$t('business/services/title-2').toString(),
          text: this.$t('business/services/text-2').toString(),
          img: 'example-slider.png',
        },
        {
          title: this.$t('business/services/title-3').toString(),
          text: this.$t('business/services/text-3').toString(),
          img: 'example-photo.png',
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { blocks } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;

      const tl = new TimelineLite();

      TweenLite.set(blocks, {
        y: 40,
      });

      tl
        .to(blocks, 0.9, {
          y: 0,
          ease,
        });

      new ScrollMagic.Scene({
        triggerElement: blocks,
        triggerHook: 1,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./advertiser-services";
</style>
