<template>
  <g clip-path="url(#clip0_146_232712)">
  <path d="M78.4474 62.3633L79.7177 57.7056L74.0015 55.5885L73.3663 59.8227L78.4474 62.3633Z" fill="#FFA895"/>
  <path opacity="0.1" d="M78.4474 62.5897L79.7177 57.932L76.3513 56.5121L77.3972 62.003L78.4474 62.5897Z" fill="black"/>
  <path d="M107.061 87.0497C107.744 85.9783 106.933 84.24 105.249 83.167C104.512 82.6973 103.73 82.4331 103.027 82.3742L102.05 81.7692L89.161 73.0401C89.161 73.0401 80.4274 63.083 78.5229 62.3066C76.6184 61.5302 75.0377 62.7356 75.0377 62.7356L74.1825 64.8903L87.878 78.3252L99.5324 84.4913L101.119 85.3671C101.47 85.98 102.039 86.5772 102.776 87.0468C104.46 88.1198 106.378 88.1211 107.061 87.0497L107.061 87.0497Z" fill="#FFA895"/>
  <path d="M73.8371 57.6008C73.8371 57.6008 71.641 58.6028 71.1249 60.9608C70.6089 63.3189 87.231 78.5916 87.231 78.5916L92.9271 75.4644L73.8371 57.6008V57.6008Z" fill="#E6E6E6"/>
  <path d="M84.0578 52.7451C84.0578 56.019 81.4038 58.673 78.1299 58.673C74.8559 58.673 72.2019 56.019 72.2019 52.7451C72.2019 49.4712 74.8559 46.8171 78.1299 46.8171C81.4038 46.8171 84.0578 49.4712 84.0578 52.7451Z" fill="#FFA895"/>
  <path d="M91.9969 138.806L96.0195 148.757L98.3483 146.428L99.1952 141.982L95.8078 137.748L91.9969 138.806Z" fill="#FFA895"/>
  <path d="M69.1641 136.783L67.3782 142.673L70.6663 145.382L74.2783 143.083L73.8072 136.806L69.1641 136.783Z" fill="#FFA895"/>
  <path d="M81.0937 79.6326L81.623 88.2069L68.4968 85.8781L69.3437 80.5853L81.0937 79.6326Z" fill="#FFA895"/>
  <path d="M81.7289 88.3128L68.4968 83.9727C68.4968 83.9727 63.6275 93.7114 68.9203 99.8511C74.2131 105.991 91.7852 140.923 91.7852 140.923L97.078 138.383L89.4564 116.153L80.9879 95.6168L81.7289 88.3128V88.3128Z" fill="#2F2E41"/>
  <path d="M66.7041 95.2518L71.0828 117.631L68.3684 137.6L74.6881 138.268L79.9833 113.678L78.0749 97.3833L66.7041 95.2518Z" fill="#2F2E41"/>
  <path d="M101.101 141.135H104.767C104.767 141.135 109.046 139.441 110.048 141.135C111.051 142.829 105.574 147.632 105.574 147.632C105.574 147.632 97.9248 151.932 96.8663 151.721C95.8077 151.509 95.3843 148.968 95.3843 148.968L94.114 146.004L96.0194 146.639C96.0194 146.639 98.56 145.157 97.9248 142.617L101.101 141.135Z" fill="#2F2E41"/>
  <path d="M68.4099 142.909L67.5507 140.974L66.1604 143.908C66.1604 143.908 63.0132 146.927 67.2045 148.541C71.3958 150.154 76.447 151.618 76.447 151.618C76.447 151.618 82.9391 150.588 82.5527 148.674C82.1663 146.761 81.4664 146.228 81.4664 146.228L78.8776 146.137L74.7086 141.965L71.7846 143.727L68.4099 142.909Z" fill="#2F2E41"/>
  <path d="M76.3303 53.9095L78.2357 53.6977L81.7289 50.2045C81.7289 50.2045 84.9046 51.8982 84.9046 50.6279C84.9046 44.7 81.3055 44.9117 78.3416 44.4883C75.3776 44.0648 75.6951 43.959 74.7424 45.5468C74.3067 46.273 72.7312 46.7112 72.7312 46.7112C72.7312 46.7112 69.7672 42.6887 68.2852 47.5581C66.8032 52.4275 68.7086 67.459 67.2267 68.7293C65.7447 69.9996 73.3117 71.268 72.4921 66.2937C71.6726 61.3194 70.1906 53.6977 71.0375 52.004C71.8843 50.3103 72.096 50.7338 72.096 50.7338C71.0466 50.3053 70.3608 57.8528 74.3178 58.2223L74.4249 54.5446C74.4249 54.5446 75.06 52.6392 76.3303 53.9095H76.3303Z" fill="#2F2E41"/>
  <path d="M78.9766 61.2135L72.6252 58.673L64.1567 84.0785L81.5807 83.4438L83.1495 75.861C83.1495 75.861 84.6813 71.9145 83.411 69.5857C82.1407 67.2569 78.9766 61.2135 78.9766 61.2135V61.2135Z" fill="#E6E6E6"/>
  <path d="M62.4494 103.771C63.7152 103.88 64.8798 102.355 65.0507 100.366C65.1255 99.4956 64.9974 98.6802 64.7236 98.0293L64.8075 96.8841L66.5716 81.4172C66.5716 81.4172 69.8556 68.7656 69.6613 66.7181C69.4671 64.6707 69.1603 64.1271 69.1603 64.1271L66.8546 64.3675L61.2935 82.7288L61.2286 95.9137L61.1876 97.7256C60.8069 98.3202 60.5416 99.1019 60.4669 99.9726C60.296 101.962 61.1836 103.662 62.4494 103.771Z" fill="#FFA895"/>
  <path d="M73.1545 60.6843C73.1545 60.6843 71.2491 59.2023 68.9203 59.8374C66.5914 60.4726 60.7577 82.2789 60.7577 82.2789L66.168 85.8781L73.1545 60.6843Z" fill="#E6E6E6"/>
  <path d="M200.448 55.338L196.734 48.441L191.562 52.5527L193.551 57.7254L200.448 55.338Z" fill="#FFB6B6"/>
  <path opacity="0.1" d="M200.448 55.338L196.734 48.441L191.562 52.5527L193.551 57.7254L200.448 55.338Z" fill="black"/>
  <path d="M191.575 52.7654C194.987 52.7654 197.753 49.9994 197.753 46.5873C197.753 43.1753 194.987 40.4092 191.575 40.4092C188.163 40.4092 185.397 43.1753 185.397 46.5873C185.397 49.9994 188.163 52.7654 191.575 52.7654Z" fill="#FFB6B6"/>
  <path d="M179.773 49.8052C179.281 48.0416 177.972 46.8658 176.85 47.179C175.728 47.4922 175.217 49.1755 175.709 50.9397C175.896 51.6472 176.25 52.2995 176.74 52.8423L178.919 60.2885L182.391 59.1919L179.875 51.9676C180.014 51.249 179.979 50.5075 179.773 49.8052V49.8052Z" fill="#FFB6B6"/>
  <path d="M197.371 54.3732L186.122 62.7938L180.465 53.9666L176.73 53.9041C176.73 53.9041 177.234 68.579 182.445 69.8188C187.656 71.0586 201.968 64.1046 202.463 59.7923C202.959 55.48 197.371 54.3732 197.371 54.3732V54.3732Z" fill="#E6E6E6"/>
  <path d="M181.862 150.027L178.627 150.027L177.088 137.549L181.863 137.549L181.862 150.027Z" fill="#FFB6B6"/>
  <path d="M170.779 149.954C170.678 150.124 170.625 150.671 170.625 150.868C170.625 151.475 171.116 151.966 171.723 151.966H181.741C182.155 151.966 182.49 151.631 182.49 151.217V150.8C182.49 150.8 182.986 149.547 181.965 148.002C181.965 148.002 180.697 149.211 178.802 147.316L178.243 146.304L174.198 149.262L171.956 149.538C171.466 149.599 171.031 149.529 170.779 149.954H170.779Z" fill="#2F2E41"/>
  <path d="M191.991 150.027L188.756 150.027L187.217 137.549L191.992 137.549L191.991 150.027Z" fill="#FFB6B6"/>
  <path d="M180.908 149.954C180.807 150.124 180.754 150.671 180.754 150.868C180.754 151.475 181.245 151.966 181.852 151.966H191.87C192.284 151.966 192.619 151.631 192.619 151.217V150.8C192.619 150.8 193.115 149.547 192.095 148.002C192.095 148.002 190.826 149.211 188.931 147.316L188.373 146.304L184.327 149.262L182.085 149.538C181.595 149.599 181.16 149.529 180.908 149.954H180.908H180.908Z" fill="#2F2E41"/>
  <path d="M177.858 87.6736L174.913 95.9765L176.976 143.612L182.228 143.987L186.167 108.731L186.73 145.3L192.545 145.676L196.815 90.2673C196.815 90.2673 198.142 86.3292 194.016 84.4535C189.889 82.5778 177.858 87.6736 177.858 87.6736V87.6736Z" fill="#2F2E41"/>
  <path d="M176.991 87.192L197.398 89.1596C197.398 89.1596 196.734 81.069 202.305 72.5804C207.876 64.0918 200.183 53.879 200.183 53.879L192.623 53.3485L184.4 57.0623L183.034 72.6106L176.991 87.192Z" fill="#E6E6E6"/>
  <path d="M197.928 51.7569C197.928 51.7569 201.642 44.8599 198.989 41.942C196.337 39.0241 194.612 36.2387 191.429 37.8303C188.246 39.422 181.216 43.7989 183.869 44.8599C186.522 45.921 188.909 45.6557 188.909 45.6557C188.909 45.6557 188.491 47.638 189.894 48.3711C191.297 49.1042 192.225 50.2979 192.225 50.2979C192.225 50.2979 193.817 48.4411 194.612 49.5021C195.408 50.5632 197.928 51.7569 197.928 51.7569V51.7569Z" fill="#2F2E41"/>
  <path d="M179.757 52.9506L172.065 57.4602C172.065 57.4602 170.408 60.5786 174.884 62.6668L183.073 57.0623L185.297 59.021L188.246 57.1949L186.124 50.5632L183.736 48.7063L181.614 48.9716L180.023 47.9105L176.839 51.359L179.757 52.9506V52.9506Z" fill="#2F2E41"/>
  <path d="M181.745 54.7419C181.253 52.9783 179.944 51.8024 178.822 52.1156C177.699 52.4288 177.189 54.1122 177.681 55.8764C177.868 56.5838 178.221 57.2362 178.712 57.7789L180.89 65.2252L184.363 64.1285L181.847 56.9042C181.986 56.1856 181.951 55.4442 181.745 54.7419V54.7419Z" fill="#FFB6B6"/>
  <path d="M199.343 59.3098L188.094 67.7304L182.437 58.9033L178.702 58.8407C178.702 58.8407 179.206 73.5156 184.417 74.7555C189.628 75.9953 203.94 69.0412 204.435 64.7289C204.931 60.4166 199.343 59.3098 199.343 59.3098V59.3098Z" fill="#E6E6E6"/>
  <path d="M38.4595 112.626C38.4595 113.463 36.4262 96.8376 36.4262 96.8376L21.6311 98.6309C18.9603 98.9547 17.1233 101.474 17.632 104.116L20.3989 118.486C20.6372 118.775 21.0039 119.048 21.4754 119.303V151.856H23.5087V120.113C25.1452 120.611 27.2583 120.997 29.6086 121.248V151.856H31.6419V121.426C35.0805 121.666 38.8468 121.626 42.2869 121.238V151.856H44.3202V120.96C44.9357 120.861 45.5354 120.749 46.1143 120.623V151.856H48.1476V120.101C49.2535 119.765 50.2443 119.368 51.0779 118.905C58.6132 114.719 38.4595 111.788 38.4595 112.626H38.4595Z" fill="#E6E6E6"/>
  <path d="M53.3569 143.011L55.8625 145.516L59.3225 147.306L58.9646 150.408L52.4024 149.692L51.5672 148.022L51.8059 146.59L51.3286 144.323L53.3569 143.011Z" fill="#FFB6B6"/>
  <path d="M58.4873 149.215L58.6922 146.583L65.7654 149.931C65.7654 149.931 66.3131 151.482 64.6075 151.721C62.9019 151.959 51.5672 151.959 51.5672 151.363C51.5672 150.766 51.2572 147.712 51.2572 147.712L58.1294 149.692L58.4873 149.215Z" fill="#2F2E41"/>
  <path d="M44.3452 136.052L42.5329 139.096L41.6492 142.89L38.5544 143.306L37.6342 136.77L39.0478 135.549L40.4942 135.429L42.5741 134.408L44.3452 136.052Z" fill="#FFB6B6"/>
  <path d="M39.5934 142.55L42.1952 142.101L40.6897 149.781C40.6897 149.781 39.321 150.693 38.6702 149.099C38.0194 147.504 35.2314 136.518 35.8096 136.371C36.3878 136.224 39.272 135.173 39.272 135.173L39.0428 142.321L39.5934 142.55Z" fill="#2F2E41"/>
  <path d="M36.4859 79.0761L36.1271 86.3721L41.8682 86.4917V80.2152L36.4859 79.0761Z" fill="#FFB6B6"/>
  <path opacity="0.1" d="M36.4859 79.0761L36.1271 86.3721L41.8682 86.4917V80.2152L36.4859 79.0761Z" fill="black"/>
  <path d="M26.7978 109.217C26.7978 109.217 24.1664 116.034 26.0801 118.785C27.9938 121.536 36.9643 123.091 36.9643 123.091L40.6721 126.56L50.4798 145.458L56.2209 144.142L48.0877 124.766C48.0877 124.766 43.3034 116.991 41.5093 115.915C39.7152 114.838 36.3745 110.797 36.3745 110.797L26.7978 109.217H26.7978Z" fill="#2F2E41"/>
  <path d="M43.0643 109.815L46.7486 112.424C46.7486 112.424 59.8091 115.915 55.6229 124.048C51.4367 132.181 45.2172 138.042 45.2172 138.042L39.7153 134.932L48.5661 122.493L33.0173 113.044L43.0643 109.815V109.815Z" fill="#2F2E41"/>
  <path d="M76.5528 99.8969C78.2842 100.245 79.5079 101.421 79.286 102.523C79.0641 103.625 77.4809 104.235 75.749 103.886C75.0552 103.757 74.4027 103.463 73.8459 103.029L66.5287 101.46L67.1564 98.5682L74.4655 99.9514C75.1469 99.7668 75.8627 99.7481 76.5528 99.8969V99.8969Z" fill="#FFB6B6"/>
  <path d="M41.5693 83.8005H34.1264L28.6518 87.5083L29.1302 102.1C29.1302 102.1 30.1306 105.765 26.7381 109.157C24.7048 111.19 25.4224 112.386 25.4224 112.386L37.5027 113.343L44.7388 111.25L42.1075 104.672L42.5859 102.639C42.5859 102.639 47.4898 99.1699 46.6525 95.5818C45.8153 91.9936 44.7388 84.578 44.7388 84.578L41.5693 83.8005Z" fill="#FF3300"/>
  <path d="M59.0799 96.1402L44.6423 84.5698C44.6423 84.5698 41.8316 85.3335 42.1952 87.9831C42.5588 90.6328 48.9671 97.3348 48.9671 97.3348L59.9838 102.333L72.5981 102.978L73.1177 99.2889L59.0799 96.1402H59.0799Z" fill="#FF3300"/>
  <path d="M39.8947 82.4848C42.6691 82.4848 44.9181 80.2358 44.9181 77.4614C44.9181 74.687 42.6691 72.4379 39.8947 72.4379C37.1203 72.4379 34.8712 74.687 34.8712 77.4614C34.8712 80.2358 37.1203 82.4848 39.8947 82.4848Z" fill="#FFB6B6"/>
  <path d="M38.5193 78.4781C38.5193 78.4781 40.9115 76.2055 41.8683 75.1291C42.8252 74.0526 46.8918 76.8036 46.0545 74.7703C45.2173 72.737 44.0212 72.2585 44.0212 72.2585C44.0212 72.2585 41.8683 67.9527 37.6821 70.4644C37.6821 70.4644 37.6821 67.8331 34.3331 68.9096C30.9841 69.986 29.4293 79.9133 30.3861 80.8702C31.343 81.827 32.2998 82.5447 31.5822 84.3388C30.8645 86.1329 31.5822 94.1465 34.2135 95.2229C36.8448 96.2994 37.3233 97.0603 36.6056 98.773C35.888 100.486 34.0939 104.313 37.4429 103.356C40.7919 102.399 37.2037 103.595 38.6389 100.486C40.0742 97.3758 43.5428 95.9406 40.7919 93.1896C38.0409 90.4387 38.1605 84.6976 37.5625 84.0995C36.9645 83.5015 35.838 78.5209 35.838 78.5209C35.838 78.5209 37.8017 77.282 38.5193 78.4781V78.4781Z" fill="#2F2E41"/>
  <path d="M201.328 152.071H0.533325V151.618H201.328C201.453 151.618 201.555 151.72 201.555 151.845C201.555 151.97 201.453 152.071 201.328 152.071Z" fill="#3F3D56"/>
  <path d="M96.9969 151.662H51.7621C48.7689 151.662 46.334 149.227 46.334 146.233V81.7649C46.334 78.7713 48.7689 76.3364 51.7621 76.3364H96.9969C99.9901 76.3364 102.425 78.7713 102.425 81.7649V146.233C102.425 149.227 99.9901 151.662 96.9969 151.662Z" fill="white"/>
  <path d="M96.9968 152.314H51.7621C48.4097 152.314 45.6823 149.586 45.6823 146.234V81.7649C45.6823 78.4121 48.4097 75.6846 51.7621 75.6846H96.9968C100.349 75.6846 103.077 78.4121 103.077 81.7649V146.234C103.077 149.586 100.349 152.314 96.9968 152.314ZM51.7621 76.1373C48.6593 76.1373 46.135 78.6618 46.135 81.7649V146.234C46.135 149.337 48.6593 151.861 51.7621 151.861H96.9968C100.1 151.861 102.624 149.337 102.624 146.234V81.7649C102.624 78.6618 100.1 76.1373 96.9968 76.1373H51.7621Z" fill="#3F3D56"/>
  <path d="M56.5629 100.913L91.9239 99.8443C94.3798 99.77 94.6154 95.9284 92.1372 96.0033L56.7762 97.0723C54.3203 97.1466 54.0847 100.988 56.5629 100.913Z" fill="#FF3300"/>
  <path d="M56.5629 113.736L91.9239 112.667C94.3798 112.593 94.6154 108.751 92.1372 108.826L56.7762 109.895C54.3203 109.969 54.0847 113.811 56.5629 113.736Z" fill="#FF3300"/>
  <path d="M56.5629 126.559L91.9239 125.49C94.3798 125.416 94.6154 121.574 92.1372 121.649L56.7762 122.718C54.3203 122.792 54.0847 126.634 56.5629 126.559Z" fill="#FF3300"/>
  <path d="M48.9225 118.585C50.188 119.817 50.5781 121.469 49.7939 122.274C49.0098 123.079 47.3486 122.733 46.0829 121.5C45.5706 121.015 45.1819 120.414 44.9494 119.747L39.6537 114.46L41.7499 112.371L47.1395 117.498C47.8121 117.713 48.4235 118.086 48.9225 118.585Z" fill="#FFB6B6"/>
  <path d="M36.3662 105.27L30.9839 87.5682C30.9839 87.5682 28.2329 86.6113 27.0369 89.0034C25.8408 91.3956 27.3453 100.545 27.3453 100.545L33.6152 110.891L43.6621 118.546L46.1739 115.795L36.3662 105.27Z" fill="#FF3300"/>
  </g>
</template>
<script>
  export default {
    
  }
</script>

