<template>
  <section class="org-services">
    <div
      class="org-services__col"
      v-for="(item, i) in services"
      :key="i"
    >
      <div class="block__intro">
        <div class="org-services__text">
          <div class="block__intro-item">
            <div v-html="item.title"></div>
          </div>
          <div
            class="block__intro-item text-md color-black-85"
            v-html="item.text"
          ></div>
        </div>
        <div class="block__intro-item org-services__image" v-if="item.img">
          <img
            :src="'/img/business/' + item.img"
            class="block__intro-image"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="block__goods">
      <div class="block__intro-item">
        <img
          :src="`/img/business/special-offers${!isDesktop ? '-mobile' : ''}.jpg`"
          width="100%"
          alt=""
        >
      </div>
    </div>
  </section>
</template>


<script lang="js">
export default {
  name: 'PartnerServices',
  data() {
    return {
      services: [
        {
          title: this.$t('business/partnerServices/title-1').toString(),
          text: this.$t('business/partnerServices/text-1').toString(),
          img: 'partner-services-1.jpg',
        },
        {
          title: this.$t('business/partnerServices/title-2').toString(),
          text: this.$t('business/partnerServices/text-2').toString(),
          img: 'partner-services-2.jpg',
        },
        {
          title: this.$t('business/partnerServices/title-3').toString(),
          text: this.$t('business/partnerServices/text-3').toString(),
          img: 'partner-services-3.jpg',
        },
      ],
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth >= 1200;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./partner-services";
</style>
