<template>
  <section class="services">
    <Row justify="center">
      <Col 
        v-for="(item, i) in services" 
        :key="i" 
        :size="{'default': '12', 'md': '6', 'lg': '3'}"
      >
        <Card class="services__block">
          <div class="card__title">{{ item.title }}</div>
          <base-illustration 
            width="204" 
            height="152" 
            :viewBox="item.viewbox || '0 0 322 240'" 
            class="services__image">
            <component :is="item.img" />
          </base-illustration>
          <p class="card__text">{{ item.text }}</p>
          <a
            v-if="item.link"
            class="services__link"
            target="_blank"
            :href="item.link.address"
          >
            {{item.link.text}} →
          </a>
        </Card>
      </Col>
    </Row>  
  </section>
</template>


<script lang="js">
import ScrollMagic from "scrollmagic";
import { TweenLite, TimelineLite } from "gsap";
import Card from "@/views/organizers/components/card.vue"
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import OnlineLearning from "@/components/illustrations/online-learning-undraw.vue";
import PhotoSession from "@/components/illustrations/photo-session.vue";
import TransferMoney from "@/components/illustrations/transfer-money-undraw.vue";
import GoodTeam from "@/components/illustrations/good-team-undraw.vue";

const targetLink = "https://docs.google.com/document/d/1zNtRhXvHRu12MX8ohL7HgjGDAJuRJ2w5/edit";

export default {
  name: "Services",
  components: {
    Card,
    BaseIllustration,
    OnlineLearning,
    PhotoSession,
    TransferMoney,
    GoodTeam
  },
  data() {
    return {
      services: [
        {
          title: this.$t("photographers/services/title-1").toString(),
          text: this.$t("photographers/services/text-1").toString(),
          img: "photo-session",
          link: {
            text: this.$t("photographers/services/reference"),
            address: targetLink
          } 
        },
        {
          title: this.$t("photographers/services/title-2").toString(),
          text: this.$t("photographers/services/text-2").toString(),
          img:"online-learning",
          viewbox: "0 0 205 152"
        },
        {
          title: this.$t("photographers/services/title-3").toString(),
          text: this.$t("photographers/services/text-3").toString(),
          img: "transfer-money",
          viewbox: "0 0 261 194"
        },
        {
          title: this.$t("photographers/services/title-4").toString(),
          text: this.$t("photographers/services/text-4").toString(),
          img:"good-team",
          viewbox: "0 0 205 152"
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;
      const tl = new TimelineLite();

      TweenLite.set(this.$el, {
        y: 40,
      });

      tl.to(this.$el, 0.9, {
          y: 0,
          ease,
      });

      new ScrollMagic.Scene({
        triggerElement: this.$el,
        triggerHook: 1,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./services";
</style>
