<template>
  <div class="business">
    <business-intro @sendRequest="showModal" />
    <business-nav :class="activeNav === 1 ? 'mb-xxl' : 'mb-xl'" @changenav="onNavChanged" />
    <div v-if="activeNav === 1" class="business__container">
      <partner-services class="mb-xxl" />
      <partner-features class="mb-xxl" @requestSettlement="showModal" />
    </div>
    <div v-if="activeNav === 2" class="business__container">
      <advertiser-services class="mb-xl" />
      <partner-features class="mb-xxl" @requestSettlement="showModal" />
    </div>
    <business-how />
    <business-actions @requestSettlement="showModal" />
    <request-modal ref="requestModal" />
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import BusinessIntro from './sections/business-intro/business-intro.vue';
import BusinessNav from './sections/business-nav/business-nav.vue';
import AdvertiserServices from './sections/advertiser-services/advertiser-services.vue';
import BusinessHow from './sections/business-how/business-how.vue';
import BusinessActions from './sections/business-actions/business-actions.vue';
import PartnerServices from './sections/partner-services/partner-services.vue';
import PartnerFeatures from './sections/partner-features/partner-features.vue';
import RequestModal from './components/request-modal.vue';

export default {
  name: "Business",
  components: {
    BusinessIntro,
    BusinessNav,
    AdvertiserServices,
    BusinessHow,
    BusinessActions,
    PartnerServices,
    PartnerFeatures,
    RequestModal,
  },
  data() {
    return {
      modal: null,
      activeNav: 1,
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  methods: {
    onNavChanged(navId) {
      this.activeNav = navId;
    },
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  },
  created() {
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
};
</script>


<style lang="scss" scoped>
  @import './business';
</style>
