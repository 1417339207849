<template>
  <div class="photographers">
    <intro class="mb-negative-xxl" @sendRequest="showModal"/> 
    <div class="photographers__container">
      <services class="mb-xxl" />
    </div>
    <how></how>
    
    <actions @sendRequest="showModal"></actions>
    <request-modal ref="requestModal"></request-modal> 
  </div>
</template>


<script lang="js">
import { mapGetters } from "vuex";
import intro from "./sections/intro/intro.vue";
import services from "./sections/services/services.vue";
import how from "./sections/how/how.vue";
import actions from "./sections/actions/actions.vue";
import requestModal from "./components/request-modal.vue";

export default {
  name: "Photographers",
  components: {
    intro,
    services,
    how,
    actions,
    requestModal
  },
  data() {
    return {
      modal: null
    }
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
  },
  watch: {
    lang() {
      window.location.reload();
    },
  },
  created() {
    if (!this.$root.useAnimations) {
      this.$root.useAnimations = window.innerWidth > 1199;
    }
  },
  mounted() {
    this.$store.state.showMenu = true;
    this.$store.state.buttonCart = true;
    this.modal = this.$refs.requestModal;
  },
  methods: {
    showModal() {
      const modal = this.modal && this.modal.$refs.modal;
      if (!modal) return;
      modal.open();
    }
  }
};
</script>


<style lang="scss" scoped>
  @import './photographers';
</style>

