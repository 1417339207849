<template>
  <how-section
    id="how--photographers"
    :title="$t('photographers/how/title')"
    :steps="steps"
    :bgi="bgi"
  >
    <template v-slot:text-block>
      <p class="how__text" v-html="$t('photographers/how/text')"></p>
      <p class="how__subtitle" v-html="$t('photographers/how/subtitle')">3</p>
    </template>
  </how-section>
</template>


<script lang="js">
import HowSection from "@/components/sections/how/how.vue";

export default {
  name: "How",
  components: {
    HowSection,
  },
  data() {
    return {
      bgi: "/img/photographers/how-bg.jpg",
      steps: [
        {
          title: this.$t("photographers/how/title-1").toString(),
        },
        {
          title: this.$t("photographers/how/title-2").toString(),
        },
        {
          title: this.$t("photographers/how/title-3").toString(),
        },
        {
          title: this.$t("photographers/how/title-4").toString(),
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
  @import './how';
</style>
