import { render, staticRenderFns } from "./features.vue?vue&type=template&id=45f43d0e&scoped=true&"
import script from "./features.vue?vue&type=script&lang=js&"
export * from "./features.vue?vue&type=script&lang=js&"
import style0 from "./features.vue?vue&type=style&index=0&id=45f43d0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f43d0e",
  null
  
)

export default component.exports