<template>
  <how-section
    id="how"
    :title="$t('organizers/intro/howItWorks')"
    :steps="steps"
    :bgi="bgi"
    
  >
    <template v-slot:text-block>
      <p class="how__text" v-html="$t('photographers/how/text')"></p>
      <p class="how__subtitle" v-html="$t('photographers/how/subtitle')">3</p>
    </template>
  </how-section>
</template>


<script lang="js">
import ScrollMagic from "scrollmagic";
import { TweenLite, TimelineLite } from "gsap";
import HowSection from "@/components/sections/how/how.vue";

export default {
  name: "How",
  components: {
    HowSection
  },
  data() {
    return {
      bgi: null,
      steps: [
        {
          title: this.$t("organizers/how/title-1").toString(),
        },
        {
          title: this.$t("organizers/how/title-2").toString(),
        },
        {
          title: this.$t("organizers/how/title-3").toString(),
        },
        {
          title: this.$t("organizers/how/title-4").toString(),
        },
        {
          title: this.$t("organizers/how/title-5").toString(),
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { steps } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const tl = new TimelineLite();
      const ease = window.Power2.easeOut;

      TweenLite.set(steps, {
        autoAlpha: 0,
        x: -24,
      });

      tl
        .staggerTo(steps, 0.75, {
          autoAlpha: 1,
          x: 0,
          ease,
        }, 0.15);

      new ScrollMagic.Scene({
        triggerElement: this.$el,
        triggerHook: 0.9,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      // this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./how";
</style>
