<template>
  <section class="features">
    <Row justify="space-between">
      <Col :size="{'default': '12', 'md': '7'}">
        <div class="features__title" v-html="$t('organizers/features/header')"></div>
        <p class="features__subtitle" v-html="$t('organizers/features/subheader')"></p>
      </Col>
      <Col :size="{'default': '12', 'md': '4'}">
        <Card>
          <Button
            wide
            variant="primary"
            size="lg"
            class="features__btn"
            :text="$t('organizers/intro/requestCalculation')"
            @click="$emit('requestSettlement')"
            mobile
          />
        </Card>  
      </Col>
    </Row>
    <div class="features__divider"></div>
    <div ref="features">
      <Row>
        <Col
          :size="{'default': '12', 'md': '4'}"
          class="features__item"
          v-for="(item, i) in features"
          :key="i"
        >
          <Card class="features__card">
            <div v-html="item.title"></div>
            <p
              v-for="(p, i) in item.text"
              :key="i"
              v-html="p"
            />
          </Card>  
        </Col>
      </Row>  
    </div>
  </section>
</template>


<script lang="js">
import ScrollMagic from "scrollmagic";
import { TimelineLite, TweenLite } from "gsap";
import Card from "@/views/organizers/components/card.vue";

export default {
  name: "Features",
  components: {
    Card
  },
  data() {
    return {
      features: [
        {
          title: this.$t("organizers/features/title-1").toString(),
          text: this.$t("organizers/features/text-1"),
        },
        {
          title: this.$t("organizers/features/title-2").toString(),
          text: this.$t("organizers/features/text-2"),
        },
        {
          title: this.$t("organizers/features/title-3").toString(),
          text: this.$t("organizers/features/text-3"),
        },
      ],
    };
  },
  methods: {
    initAnimation() {
      const { features } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const ease = window.Power2.easeOut;
      
      const tl = new TimelineLite();

      TweenLite.set(features, {
        y: 40,
      });

      tl
        .to(features, 0.9, {
          y: 0,
          ease,
        }, 0.1);

      new ScrollMagic.Scene({
        triggerElement: features,
        triggerHook: 1,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./features";
</style>
