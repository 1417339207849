<template>
  <intro-section
    :bgi="`/img/photographers/intro-bg.jpg`"
  >
    <div 
      slot="title"
      class="intro__title"
      v-html="$t('photographers/intro/header')"></div>
    <p
      slot="subtitle"
      class="intro__subtitle" 
      v-html="$t('photographers/intro/subheader')">
    </p>
    <template v-slot:actions>
      <Button
        class="intro__primary-bg intro__btn"
        variant="primary"
        size="lg"
        :text="$t('common/send-request')"
        @click="$emit('sendRequest')"
        mobile
      />
    </template>
  </intro-section>
</template>


<script lang="js">
import IntroSection from "@/components/sections/intro/intro.vue";

export default {
  name: "PhotographersIntro",
  components: {
    IntroSection,
  }
}
</script>


<style lang="scss" scoped>
  @import "./intro";
</style>
