<template>
  <Modal
    name="request-modal"
    align="center"
    ref="modal"
    class="request-modal"
    @close="onClosed"
  >
    <Icon
      v-if="formSubmitted"
      slot="icon"
      name="check-circle"
      class="request-modal__success-icon"
    />
    <div v-if="formSubmitted" class="form__success-wrap">
      <div class="request-modal__title" v-html="$t('organizers/requestModal/sentTitle')"></div>
      <p class="request-modal__subtitle" v-html="$t('organizers/requestModal/sentSubtitle')"></p>
      <Button 
        class="form__btn"
        variant="secondary"
        @click="closeModal()"
        :text="$t('organizers/requestModal/sentButton')"
        mobile
      />
    </div>
    <div v-else>
      <div class="request-modal__title" v-html="$t('organizers/requestModal/title')"></div>
      <p class="request-modal__subtitle" v-html="$t('organizers/requestModal/subtitle')"></p>
      <form class="form request-modal__form" @submit.prevent>
        <Field 
          class="form__field"
          :label="$t('organizers/requestModal/nameLabel')" 
          row
        >
          <Input
            type="text"
            :state="$v.fields.name.$error ? 'error' : null"
            @input="$v.fields.name.$touch()"
            :placeholder="$t('organizers/requestModal/namePlaceholder')"
            autocomplete="off"
            mobile
            v-model.trim="fields.name"
            :disabled="sending"
          />
          <template v-slot:msg>
            <div v-if="$v.fields.name.$error" class="form__err-message">
              {{ $t("organizers/requestModal/nameMsg")}}
            </div>
          </template>
        </Field>
        
        <Field 
          class="form__field"
          :label="$t('organizers/requestModal/phoneLabel')"
           row
        >
          <Input
            type="tel"
            ref="tel"
            @input="$v.fields.phone.$touch()"
            :state="$v.fields.phone.$error ? 'error' : null"
            v-model="fields.phone"
            placeholder=""
            autocomplete="off"
            mobile
            :disabled="sending"
          />
          <template v-slot:msg>
            <div v-if="$v.fields.phone.$dirty && !$v.fields.phone.required" class="form__err-message">
              {{ $t("organizers/requestModal/phoneMsg") }}
            </div>
            <div v-else-if="$v.fields.phone.$dirty && !$v.fields.phone.phoneMask" class="form__err-message">
              {{ $t("organizers/requestModal/phoneMsg-1") }}
            </div>
          </template>
        </Field> 
        
        <Field 
          class="form__field"
          :label="$t('organizers/requestModal/emailLabel')"
          row
        >
          <Input
            autocomplete="off"
            mobile
            :placeholder="$t('organizers/requestModal/emailPlaceholder')"
            type="email"
            v-model.trim="fields.email"
            @input="$v.fields.email.$touch()"
            :state="$v.fields.email.$error ? 'error' : null"
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div v-if="$v.fields.email.$error" class="form__err-message">
              {{ $t("organizers/requestModal/emailMsg")}}
            </div>
          </template>
        </Field>
        
        <Field
          class="form__field" 
          :label="$t('organizers/requestModal/companylLabel')"
          row
        >
          <Input
            autocomplete="off"
            mobile
            :placeholder="$t('organizers/requestModal/companyPlaceholder')"
            v-model.trim="fields.company"
            @input="$v.fields.company.$touch()"
            :state="$v.fields.company.$error ? 'error' : null"
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div v-if="$v.fields.company.$error" class="form__err-message">
              {{ $t("organizers/requestModal/companyPlaceholder")}}
            </div>
          </template>
        </Field>
        
        <Field 
          class="form__field"
          :label="$t('organizers/requestModal/eventLabel')" 
          row
        >
          <Input
            autocomplete="off"
            mobile
            :placeholder="$t('organizers/requestModal/eventPlaceholder')"
            v-model.trim="fields.event"
            @input="$v.fields.event.$touch()"
            :state="$v.fields.event.$error ? 'error' : null"
            :disabled="sending"
          ></Input>
          <template v-slot:msg>
            <div v-if="$v.fields.event.$error" class="form__err-message">
              {{ $t('organizers/requestModal/eventPlaceholder') }}
            </div>
          </template>
        </Field>

        <div v-if="sentState && !isSuccess" class="form__error">
          <div>An error has occurred: {{sentState}}. Please try again later.</div>
        </div>

        <div class="form__agreement">
          <Button
            class="form__btn"
            variant="primary"
            size="lg"
            wide
            :text="$t('organizers/intro/requestCalculation')"
            :disabled="disabledSubmit || sending"
            :loading="sending"
            @click="submit"
            mobile
          />
          <p>
            {{ $t("organizers/requestModal/privacy-1") }}
            <a href="/Privacy_Policy.pdf" target="_blank" v-html="$t('organizers/requestModal/privacy-2')"></a>
          </p>
        </div>
      </form>  
    </div>  
  </Modal>
</template>

<script>
import Modal from "@rr-component-library/modal/src/main";
import Field from "@rr-component-library/field/src/main";
import Icon from "@rr-component-library/icon/src/main";
import boringApi from '@/services/api/boring-api';
import TENANT from "@/tenant.config";

import { validationMixin } from "vuelidate";
import { required, minLength, email, maxLength } from "vuelidate/lib/validators";

const phoneMask = (value) => {
  const phoneMask = /^\+?[1-9][0-9()+]{7,20}$/g
  return phoneMask.test(value);
}

const { v4: uuidv4 } = require('uuid');

export default {
  name: "RequestModal",
  mixins: [validationMixin],
  components: {
    Modal,
    Field,
    Icon
  },
  data() {
    return {
      formSubmitted: false,
      isDirtySubmitButton: false,
      sending: false,
      sentState: null,
      fields: {
        name: null,
        phone: null,
        email: null,
        company: null,
        event: null,
      }
    }
  },
  validations: {
    fields: {
      name: {
        required,
        minLength: minLength(4)
      },
      phone: {
        required,
        phoneMask,
        maxLength: maxLength(20)
      },
      email: {
        required,
        email
      },
      company: {
        required
      },
      event: {
        required
      }
    }
  },
  computed: {
    disabledSubmit() {
      return this.isDirtySubmitButton && this.$v.fields.$anyError;
    },
    isSuccess() {
      return this.sentState === 204;
    },
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
    onClosed() {
      setTimeout(() => {
        this.resetFields();
        this.$v.$reset();
      }, 500);
    },
    submit() {
      this.isDirtySubmitButton = true;
      this.$v.fields.$touch();
      this.empty = !this.$v.fields.$anyDirty;
      this.errors = this.$v.fields.$anyError;
 
      if (this.errors || this.empty) {
        return;
      } 
      this.saveData();
    },
    getData() {
      const { fields } = this;
      return {
        notificationId: uuidv4(),
        notificationType: 'organizer_questionnaire_completed',
        tenantId: window.MYSPORT_PHOTO_APP_SETTINGS?.tenantId || TENANT.tenantId,
        parameters: {
          name: fields.name,
          phoneNumber: this.cleanPhone(fields.phone),
          email: fields.email,
          company: fields.company,
          event: fields.event,
        },
      };
    },
    saveData() {
      this.sending = true;
      this.sentState = null;
      boringApi.saveAnswers(this.getData())
        .then((response) => {
          if (response.status === 204) {
            this.sentState = response.status;
            setTimeout(() => {
              this.sending = false;
              this.formSubmitted = true;
            }, 1000);
          }
        })
        .catch((error) => {
          const status = error.response && error.response.status;
          setTimeout(() => {
            this.sentState = status;
            this.sending = false;
          }, 1000);
        });
    },
    cleanPhone(phone) {
      return phone ? `+${phone.replace(/\D/g, '')}` : null;
    },
    resetFields() {
      this.formSubmitted = false;
      this.isDirtySubmitButton = false;
      this.sending = false;
      this.sentState = null;
      this.fields = {
        name: null,
        phone: null,
        email: null,
        company: null,
        event: null,
      };
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "./request-modal.scss";
</style>