<template>
  <section id="how" class="how" 
    :style="bgi ? `background-image: url(${bgi})` : ''"
  >
    <div v-if="bgi" class="how__overlay"></div>
    <div class="how__container">
      <div v-if="title" class="how__title" v-html="title"></div>
      <slot name="text-block"></slot>
      <div class="section__group">
        <div class="steps">
          <div
            class="steps__item"
            v-for="(item, i) in steps"
            :key="i"
          >
            <div class="step">
              <div class="step__num"><span class="m-0">{{ i + 1 }}</span></div>
              <div
                class="step__title"
                v-html="item.title"
              ></div>
            </div>
            <div
              ref="steps"
              class="steps__item-arrow"
              v-if="i < steps.length - 1"
            >
              <Icon v-if="arrows" name="arrow-right" size=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="js">
import ScrollMagic from 'scrollmagic';
import { TweenLite, TimelineLite } from 'gsap';

export default {
  name: 'How',
  props: {
    bgi: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    steps: {
      type: Array,
      default: ()=>([])
    },
    arrows: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    initAnimation() {
      const { steps } = this.$refs;
      const controller = new ScrollMagic.Controller();
      const tl = new TimelineLite();
      const ease = window.Power2.easeOut;

      TweenLite.set(steps, {
        autoAlpha: 0,
        x: -24,
      });

      tl
        .staggerTo(steps, 0.75, {
          autoAlpha: 1,
          x: 0,
          ease,
        }, 0.15);

      new ScrollMagic.Scene({
        triggerElement: this.$el,
        triggerHook: 0.9,
        reverse: false,
      })
        .setTween(tl)
        .addTo(controller);
    },
  },
  mounted() {
    if (this.$root.useAnimations) {
      this.initAnimation();
    }
  },
};
</script>


<style lang="scss" scoped>
  @import "./how";
</style>
